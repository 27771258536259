import React, {useEffect, useLayoutEffect, useRef } from "react";
import { Bounce } from "react-reveal";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
export const FilmEditing = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      {" "}
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background:
              "url(assets/imgs/specilization/acting.jpg?v=0.0.4) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Film Editing &amp; DI
                  </h2>
                  <p className="text-white">
                    Not just a software but a sense of film aesthetics makes a
                    good editor
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                            <h1>Diploma In Filmmaking</h1>
                        </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Program Intro</h2>
                  {/* <!-- <ul className="job__detail-meta">
                                    <li><span>Location</span> 2541, Austin, New York</li>
                                    <li><span>Date</span> 24, June 2022</li>
                                    <li><span>Job Type</span> Full time (Remote)</li>
                                </ul> --> */}
                  <div className="job__detail-content">
                    <p>
                      Editing is where movies are made or broken. Many films
                      have been saved and many films have been ruined in the
                      editing room. Film Editing is now something almost
                      everyone can do at a simple level and enjoy, but to take
                      it to a higher level requires the dedication and
                      persistence that any art form does. Learn about all the
                      key aspects of Filmmaking to open doors for you in the
                      film industry.
                    </p>
                    <h2>Program Overview</h2>
                    <p>
                      The Young Aspirants who wish to pursue their passion of
                      Editing into a career. this fruitful course is specially
                      designed with various elective specializations where
                      students can master their desired technical skills along
                      with 30% Theory and 70% Practical knowledge.
                    </p>
                    <p>
                      Students will get access to the ‘State-Of-The-Art'
                      facilities as well as professional studios with
                      world-class equipment and technology.
                    </p>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Film City Editing Studios Sound Recording & Mixing Studio
                      Colour Lab (DI) Preview Theatre (4K, 9.2.1 Dolby) Film
                      Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory VFX Lab Miniature
                      Studio
                    </p>
                    <h2 className="mb-3">Program Benefits </h2>
                    <ul>
                      <li>On job Training</li>
                      <li>
                        After successful completion of this course, students
                        will be able to Edit a short film and also start working
                        as Assistant Editor for Feature Films, Television
                        Serials, Web Series, Television Commercials, etc.
                      </li>
                      <li>
                        Participation in Aurangabad International Film Festival
                      </li>
                      <li>
                        Certificate of Completion from MGM University,
                        Aurangabad
                      </li>
                      <li>Opportunity to be an MGMU Alumni</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!-- <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                            <div className="job__detail-sidebar">
                                <h5 className="mb-3">Program Benefits </h5>
                                <ul>
                                    <li>In-depth, hands-on, practical training</li>
                                    <li>After successful completion of this course, students will be able to Edit a
                                        short film and also start working as Assistant Editor for Feature Films,
                                        Television Serials, Web Series, Television Commercials, etc.</li>
                                    <li>Audition assistance</li>
                                    <li>Participation in In-house and Professional Film Projects</li>
                                    <li>Film festival access</li>
                                    <li>Easy access to Studios and shooting facilities</li>
                                </ul>
                            </div>
                        </div> --> */}
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5">
          <div className="container">
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-film-editing">
                      <h3 className="ps-title">
                        MA in FILM
                        <br /> Editing &amp; DI
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-film-editing">
                      <h3 className="ps-title">
                        BA (HONS) in <br /> FILM Editing &amp; DI
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-film-editing">
                      <h3 className="ps-title">
                        Diploma in Film <br /> Editing &amp; DI
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/film-editing-short-programs">
                      <h3 className="ps-title">
                        Short Programs <br /> (6 Months)
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                  <div
                    className="btn_wrapper"
                    style={{ position: "relative", alignItems: "flex-start" }}
                  >
                    <Bounce top duration={1200}>
                      <Link
                        to="#"
                        target="_blank"
                        className="wc-btn-primary btn-hover btn-item"
                      >
                        <span></span>
                        Download
                        <br /> brochure{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </Bounce>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
