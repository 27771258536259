import React from "react";
import { Header } from "../../Components/Common/Header";
import { Testimonials } from "../Homepage/Homepage Components/Testimonials";
import { ImageSlider4 } from "../../Components/Common/ImageSlider";

export const StudentReviews = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.2.1")})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Students Reviews
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="swiper testimonial__slider-4">
              <div className="swiper-wrapper">
                {/* <ImageSlider2 /> */}
        <ImageSlider4 />
              </div>
            </div>
      </main>
    </>
  );
};
