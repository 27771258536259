import React from "react";
import Reveal from "react-reveal";
import styled from "styled-components";
import Img1 from "../../../assets/imgs/service/2/1.jpg";
import Img2 from "../../../assets/imgs/service/2/2.jpg";
import Img3 from "../../../assets/imgs/service/2/3.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const SwivelText = styled.h1`
  transform-origin: center;
  transform: scale(0) rotateX(90deg);
`;

export const FilmMakingPrograms = () => {
  const programs = [
    {
      imgSrc: Img1,
      title: "Diploma in Filmmaking (eligiblity 10th+2)",
      link: "/certificate-program-2/",
      specializationsName: [
        "Acting For Films",
        "Cinematography & DI",
        "Film Direction & Screenwriting",
        "Film Editing & DI",
        "Music Production & Sound Design",
        "Production Design & Art Direction",
        "VFX & Animation",
      ],
      specializationsLink: [
        "acting-for-films",
        "cinematography",
        "film-direction",
        "film-editing",
        "sound-design-music-production",
        "production-design-art-direction",
        "vfx-animation",
      ],
    },
    {
      imgSrc: Img2,
      title: "Under Graduation in Filmmaking  (eligiblity 10th+2)",
      link: "/graduation-program/",
      specializationsName: [
        "Acting For Films",
        "Cinematography & DI",
        "Film Direction & Screenwriting",
        "Film Editing & DI",
        "Music Production & Sound Design",
        "Production Design & Art Direction",
        "VFX & Animation",
      ],
      specializationsLink: [
        "acting-for-films",
        "cinematography",
        "film-direction",
        "film-editing",
        "sound-design-music-production",
        "production-design-art-direction",
        "vfx-animation",
      ],
    },
    {
      imgSrc: Img3,
      title: "Post Graduation  (eligiblity Any Graduate)",
      link: "/ma-in-filmmaking/",
      specializationsName: [
        "Acting For Films",
        "Cinematography & DI",
        "Film Direction & Screenwriting",
        "Film Editing & DI",
        "Music Production & Sound Design",
        "Production Design & Art Direction",
        "VFX & Animation",
      ],
      specializationsLink: [
        "acting-for-films",
        "cinematography",
        "film-direction",
        "film-editing",
        "sound-design-music-production",
        "production-design-art-direction",
        "vfx-animation",
      ],
    },
  ];

  return (
    <>
      <section className="portfolio__area-5 service__area-2">
        <div id="items__border"> </div>
        <div className="container">
          <div
            className="row "
            // style={{ paddingBottom: "10px", paddingTop: "0px" }}
          >
            <div
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 text-center"
              style={{ zIndex: "0" }}
            >
              <div className="sec-title-wrapper">
                {" "}
                <h2 className="sec-title animate__animated animate__fadeInUp ">
                  <Reveal bottom>FILMMAKING PROGRAMS</Reveal>{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row text-center">
            <div className="portfolio__inner-5">
              {programs.map((program, index) => (
                <div className="portfolio__item-5" key={index}>
                  <Link to={program.link}>
                    <LazyLoadImage src={program.imgSrc} alt="Portfolio Image" />
                    <h2 className="portfolio__title-5 ">{program.title}</h2>
                    <div className="portfolio__content-5 portfolio__content-5-bottom text-start">
                      <h3 className="portfolio__title-5 mb-2">
                        Specializations
                      </h3>
                      <ol className="portfolio__name-5 list-group list-group-numbered mt-1">
                        {program.specializationsLink.map(
                          (specializationLink, i) => (
                            <Link to={`${specializationLink}/`} key={i}>
                              <li className="list-group-item">
                                {program.specializationsName[i]}
                              </li>
                            </Link>
                          )
                        )}
                      </ol>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
