import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  ButtonDiplomaBrochure,
  ButtonDiplomaSyllabus,
} from "../../../Components/Common/Button/Button";
import { Header } from "../../../Components/Common/Header";

export const DiplomainFilmmaking = () => {
  return (
    <>
      <Header />

      <main>
        <section className="job__detail-top pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    Diploma In Filmmaking
                  </h2>
                  <p className="text-white">
                    Think Film ! Breathe Film ! Live Film !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8">
                <div className="job__detail-wrapper">
                  <div className="job__detail-content">
                    <h2>Program Highlights</h2>
                    <ul>
                      <li>Compulsory Short Film Production every semester</li>
                      <li>Masterclass by Renowned Directors</li>
                      <li>
                        Chance to participate in Feature Film Production,
                        Release, and Marketing Process with Graduation Students
                      </li>
                    </ul>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Film City Editing Studios Sound Recording &amp; Mixing
                      Studio Color Lab (DI) Preview Theatre (4K, 9.2.1 Dolby)
                      Film Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory VFX Lab Miniature
                      Studio
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                <div
                  className="pin-spacer"
                  style={{
                    transform: "translate(0px, 0px)",
                    margin: "0px",
                    maxWidth: "267px",
                    width: "267px",
                    maxHeight: "500px !important",
                    // minHeight: `${minSidebarHeight}px !important`, // Set minimum height
                    padding: "40px 30px",
                    position: "relative",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className="job__detail-sidebar"
                    // ref={sidebarRef}
                    style={{
                      transform: "translate(0px, 0px)",
                      margin: "0px",
                      maxWidth: "267px",
                      width: "267px",
                      maxHeight: "100%",
                      // minHeight: `${minSidebarHeight}px !important`, // Set minimum height
                      padding: "40px 30px",
                      position: "static",
                      boxSizing: "border-box",
                    }}
                  >
                    <h5 className="mb-3">Speak with Program In-charge</h5>
                    <ul>
                      <li>
                        <span>Prof. Kalyani Shinde</span> +91 86055 66225
                      </li>
                    </ul>
                    <h5 className="mt-5 mb-3">Fees Structure</h5>
                    <ul>
                      <li>
                        <span>Duration:</span> 1 Year (2 Semesters)
                      </li>
                      <li>
                        <span>Annual Fees:</span> 1,50,000/- + University Fees
                      </li>
                      <li>
                        <Link
                          to="#"
                          target="_blank"
                        >
                          View More
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5 pt-30 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
                <h2 className="sec-title animation__char_come">
                  Diploma in Filmmaking
                </h2>
              </div>
              <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                <div className="sec-text">
                  <p>
                    Qualify with a Diploma in Filmmaking in 1 year. Our
                    intensive, full-time program covers all the key disciplines
                    of filmmaking.
                  </p>
                </div>
              </div>
              <p className="mb-3 mt-3">
                Students can opt for one Major out of the following
              </p>
            </div>
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-acting-for-films">
                      <h3 className="ps-title">
                        ACTING <br />
                        FOR FILMS
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-film-direction">
                      <h3 className="ps-title">
                        FILM DIRECTION <br />
                        &amp; SCREENWRITING
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-film-editing">
                      <h3 className="ps-title">
                        EDITING <br />
                        &amp; DI
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-sound-design-and-music-production">
                      <h3 className="ps-title">
                        SOUND DESIGN <br />
                        &amp; MUSIC PRODUCTION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-cinematography">
                      <h3 className="ps-title">
                        CINEMATOGRAPHY <br />
                        &amp; DI
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-vfx-and-animation">
                      <h3 className="ps-title">
                        VFX &amp; <br />
                        ANIMATION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-production-design-and-art-direction">
                      <h3 className="ps-title">
                        PROD. DESIGN <br />
                        &amp; ART DIRECTION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                  <ButtonDiplomaBrochure />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <ButtonDiplomaSyllabus />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
