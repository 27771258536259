import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import Img1 from "../../../assets/imgs/service/1/1.jpg"
import Img2 from "../../../assets/imgs/service/1/2.png"
import Img3 from "../../../assets/imgs/service/1/3.png"
export const IndustrySpeaks = () => {
  const [activeImage, setActiveImage] = useState(1);
  const [hoveredService, setHoveredService] = useState(1);

  const handleMouseEnter = (imageId) => {
    setActiveImage(imageId);
  };

  const handleTitleWrapperHover = (serviceId) => {
    setHoveredService(serviceId);
  };

  return (
    <>
      <section className="service__area pt-110 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="sec-title-wrapper wrap">
                <h2 className="sec-sub-title title-anim">
                  <Fade bottom>Industry Speaks</Fade>
                </h2>
                <h3 className="sec-title title-anim">
                  <Fade bottom>Stalwarts About MGM School Of Film Arts</Fade>
                </h3>
              </div>
            </div>
          </div>

          <div className="service__list-wrapper">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-0 col-md-0">
                <div className="service__img-wrapper">
                  <img
                    src={Img1}
                    alt="Service Image"
                    className={`service__img img-1 ${
                      activeImage === 1 ? 'active' : ''
                    }`}
                  />
                  <img
                    src={Img2}
                    alt="Service Image"
                    className={`service__img img-2 ${
                      activeImage === 2 ? 'active' : ''
                    }`}
                  />
                  <img
                    src={Img3}
                    alt="Service Image"
                    className={`service__img img-3 ${
                      activeImage === 3 ? 'active' : ''
                    }`}
                  />

                  <span className={`shape-box shape-box-1 ${hoveredService === 1 ? 'current' : ''}`}></span>
                  <span className={`shape-box shape-box-2 ${hoveredService === 2 ? 'current' : ''}`}></span>
                  <span className={`shape-box shape-box-3 ${hoveredService === 3 ? 'current' : ''}`}></span>
                  <span className={`shape-box shape-box-4 ${hoveredService === 4 ? 'current' : ''}`}></span>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12">
                <div className="service__list">
                  <div
                    className="service__item animation_home1_service"
                    data-service="1"
                    onMouseEnter={() => handleMouseEnter(1)}
                  >
                    <div className="service__number">
                      <span>01</span>
                    </div>
                    <div
                      className="service__title-wrapper" 
                      onMouseEnter={() => handleTitleWrapperHover(1)}
                    >
                      <img
                        src={Img1}
                        alt="Service Image"
                      />
                      <h4 className="service__title">
                        <Fade duration={2000} left>
                          Girish Kasarwalli
                        </Fade>
                      </h4>
                      <p className="text-white pt-2">Director</p>
                    </div>
                    <div className="service__text">
                      <p>
                        <Fade left duration={2000}>
                          MGM Film Arts is an ideal platform for budding
                          filmmakers. The atmosphere and facilities at MGM Film
                          Arts are ideal for grooming budding artists of cinema.
                        </Fade>
                      </p>
                    </div>
                  </div>
                  <div
                    className="service__item animation_home1_service"
                    data-service="2"
                    onMouseEnter={() => handleMouseEnter(2)}
                  >
                    <div className="service__number">
                      <span>02</span>
                    </div>
                    <div
                      className="service__title-wrapper"
                      onMouseEnter={() => handleTitleWrapperHover(2)}
                    >
                      <img
                        src={Img2}
                        alt="Service Image"
                      />
                      <h4 className="service__title">
                        <Fade left duration={2000}>
                          Shriram Raghavan
                        </Fade>
                      </h4>
                      <p className="text-white pt-2">Director</p>
                    </div>
                    <div className="service__text">
                      <p>
                        <Fade left duration={2000}>
                          MGM Film Arts is one of the finest Filmmaking
                          Institutes. The State-Of-The-Art studio facility will
                          nurture the talent of budding filmmakers.
                        </Fade>
                      </p>
                    </div>
                  </div>
                  <div
                    className="service__item animation_home1_service"
                    data-service="3"
                    onMouseEnter={() => handleMouseEnter(3)}
                  >
                    <div className="service__number">
                      <span>03</span>
                    </div>
                    <div
                      className="service__title-wrapper"
                      onMouseEnter={() => handleTitleWrapperHover(3)}
                    >
                      <img
                        src={Img3}
                        alt="Service Image"
                      />
                      <h4 className="service__title">
                        <Fade left duration={2000}>
                          Madhur Bhandarkar
                        </Fade>
                      </h4>
                      <p className="text-white pt-2">Director</p>
                    </div>
                    <div className="service__text">
                      <p>
                        <Fade left duration={2000}>
                          The MGM School of Film Arts is an ideal platform for
                          budding filmmakers. The atmosphere and facilities at
                          MGM Film Arts are ideal for grooming budding artists
                          of cinema.
                        </Fade>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
