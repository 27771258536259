import React, { useState } from "react";
import { Header } from "../../Components/Common/Header";

export const Walkthrough = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const correctPassword = process.env.REACT_APP_PASSWORD;

  const [formData, setFormData] = useState({
    type: 1,
    RequestTypeId: "1",
    FirstName: "",
    LastName: "",
    Phone: "",
    Email: "",
    Gender: "",
    ProgramName: "",
  });

  const {
    type,
    RequestTypeId,
    FirstName,
    LastName,
    Phone,
    Email,
    Gender,
    ProgramName,
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create payload
    const payload = { ...formData };
    console.log(payload);

    try {
      // Make POST request to the API
      const response = await fetch(process.env.REACT_APP_SEND_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      if (response.ok) {
        const responseData = await response.json();
        console.log("Enquiry submitted successfully:", responseData.Message);
        setSuccessMessage(responseData.Message);
        setErrorMessage(""); // Clear any existing error message
        // Reset form fields
        setFormData({
          type: 1,
          RequestTypeId: "1",
          FirstName: "",
          LastName: "",
          Phone: "",
          Email: "",
          Gender: "",
          ProgramName: "",
        });
      } else {
        // Handle non-success HTTP responses
        if (response.status === 0) {
          throw new Error("Not connected. Verify your network.");
        } else if (response.status === 404) {
          throw new Error("Requested page not found. [404]");
        } else if (response.status === 500) {
          throw new Error("Internal Server Error [500].");
        } else {
          const responseData = await response.json();
          if (responseData && responseData.message) {
            throw new Error("Error: " + responseData.message);
          } else {
            throw new Error("Uncaught Error.");
          }
        }
      }
      const data = await response.json();
      console.log("Form submitted successfully!");
      console.log(data); // Response from the API
      setFormData({});
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Header />
      <main>
        <section
          id="contact"
          className="form mt-5"
          style={{
            background: `url(${require("../../assets/imgs/bg/5.jpg?v=0.1.8")})`,
          }}
        >
          <div className="container pt-150 pb-30">
            <div className="row">
              <div className="col-xl-7 col-lg-6"></div>
              <div className="col-xl-5 col-lg-6 col-md-8 pb-3">
                <div className="ha-enquiry-block" id="dvEnquiryBlock">
                  <div className="ha-enquiry-block-inner">
                    <h1 className="ha-enquiry-block-heading">
                      Apply For Walk Through Cinema{" "}
                    </h1>
                    <form
                      className="ha-enquiry-form animate__animated animate__fadeInUp"
                      id="workshopScholarshipForm"
                      onSubmit={handleSubmit}
                      data-parsley-validate
                    >
                      <div id="formResponse">
                        {errorMessage && (
                          <div className="alert alert-danger fadeIn animated">
                            <i className="icon ion-information-circled"></i>{" "}
                            Error! {errorMessage}
                          </div>
                        )}
                        {successMessage && (
                          <div className="alert alert-success fadeIn animated">
                            <i className="icon ion-information-circled"></i>{" "}
                            {successMessage}
                          </div>
                        )}
                      </div>{" "}
                      <input type="hidden" id="txtRequestTypeId" value="1" />
                      <div id="formResponse"></div>
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtEF1Name"
                              name="FirstName"
                              placeholder="First name"
                              data-parsley-minlength="3"
                              minLength="3"
                              data-parsley-maxlength="60"
                              maxLength="60"
                              data-parsley-error-message="Please enter a valid name"
                              required
                              style={{ textTransform: "capitalize" }}
                              value={FirstName}
                              onChange={handleChange}
                            />
                            <label htmlFor="txtEF1Name">First Name</label>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtEF2Name"
                              name="LastName"
                              placeholder="Last name"
                              data-parsley-minlength="3"
                              minLength="3"
                              data-parsley-maxlength="60"
                              maxLength="60"
                              data-parsley-error-message="Please enter a valid name"
                              required
                              style={{ textTransform: "capitalize" }}
                              value={LastName}
                              onChange={handleChange}
                            />
                            <label htmlFor="txtEF2Name">Last Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="tel"
                          className="form-control txtEFPhone"
                          id="txtEF1Phone"
                          name="Phone"
                          placeholder="My Phone no."
                          minLength="10"
                          maxLength="10"
                          required
                          value={Phone}
                          onChange={handleChange}
                        />
                        <label htmlFor="txtEF1Phone">My Phone no.</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="Email"
                          className="form-control"
                          id="txtEF1Email"
                          name="Email"
                          placeholder="My Email address"
                          minLength="3"
                          maxLength="60"
                          required
                          value={Email}
                          onChange={handleChange}
                        />
                        <label htmlFor="txtEF1Email">My Email address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <select
                          className="form-select ddlGender"
                          id="ddlGender"
                          name="Gender"
                          required
                          value={Gender}
                          onChange={handleChange}
                        >
                          <option value="" hidden selected>
                            Gender
                          </option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                      <div className="form-floating mb-3">
                        <select
                          className="form-select ddlProgramName"
                          id="ddlProgramName"
                          name="ProgramName"
                          required
                          value={ProgramName}
                          onChange={handleChange}
                        >
                          <option value="" hidden selected>
                            Specialization
                          </option>
                          <option value="Acting For Films">
                            Acting For Films
                          </option>
                          <option value="Film Editing & DI">
                            Film Editing &amp; DI
                          </option>
                          <option value="Film Direction & Screenwriting">
                            Film Direction &amp; Screenwriting
                          </option>
                          <option value="Production Design & Art Direction">
                            Production Design &amp; Art Direction
                          </option>
                          <option value="Cinematography & DI">
                            Cinematography &amp; DI
                          </option>
                          <option value="VFX & Animation">
                            VFX &amp; Animation
                          </option>
                          <option value="Sound Design & Music Production">
                            Sound Design &amp; Music Production
                          </option>
                        </select>
                      </div>
                      <div className="d-grid gap-2 mb-3">
                        <button
                          type="submit"
                          className="btn btn-theme-primary btn-form-submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
