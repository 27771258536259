import React from 'react'
import { Header } from '../../Components/Common/Header'

export const ProfessionalProjects = () => {
  return (
    <>
    <Header />
    <main>
  <section
    className="job__detail-top pt-150 pb-100"
    style={{ background: "url(assets/imgs/bg/mgmu.jpg?v=0.2.1) !important" }}
  >
    <div className="container">
      <div className="row">
        <div className="col-xxl-12">
          <div className="sec-title-wrapper">
            <h2 className="sec-title animation__char_come text-white mt-5">
              Professional Projects
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="portfolio__area-3 pt-5">
    <div className="container">
      <div className="row">
        <div className="col-xxl-12">
          <div className="swiper portfolio__slider-3">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="portfolio__slide-3">
                  <img
                    src="assets/imgs/portfolio/3/6.jpg?v=0.2.1"
                    alt="Portfolio Image1"
                  />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="portfolio__slide-3">
                  <img
                    src="assets/imgs/portfolio/3/7.jpg?v=0.2.1"
                    alt="Portfolio Image2"
                  />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="portfolio__slide-3">
                  <img
                    src="assets/imgs/portfolio/3/8.jpg?v=0.2.1"
                    alt="Portfolio Image3"
                  />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="portfolio__slide-3">
                  <img
                    src="assets/imgs/portfolio/3/9.jpg?v=0.2.1"
                    alt="Portfolio Image4"
                  />
                </div>
              </div>
            </div>
            <div className="swiper-btn">
              <div className="pp-prev">
                <i className="fa-solid fa-arrow-left" />
              </div>
              <div className="pp-next">
                <i className="fa-solid fa-arrow-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="job__detail">
    <div className="container g-0">
      <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
          <div className="job__detail-wrapper">
            <h2 className="sec-title">“Kaal” - My first feature Short film.</h2>
            <div
              className="job__detail-content"
              style={{ paddingTop: "0 !important" }}
            >
              <p>
                I'm Aniket Ballal, student of MGM FILM ARTS, MGM University.
                Here I'm studying a course called Acting for Films. I would like
                to share my experience with you.{" "}
              </p>
              <p>
                {" "}
                “Kaal” - My first feature Short film. Basically working in a
                professional film project with superstars was a dream to me. I
                always wanted to be in a professional project, and my dream came
                true only because of MGM Film Arts.
              </p>
              <p>
                {" "}
                So, When we first gathered to discuss the script we learned that
                how a true industry project works. In this discussion we learned
                that how should we put our emotions in dialogues, how the
                dialogue delivery should be, our Director Pratima Joshi told us
                each and every thing very calmly.
              </p>
              <p>
                Then on the first day of shooting we've started the shooting
                with the Virtuous actress Mukta Barve. I was amazed that how she
                was acting and behaving in a very pleasent and professional
                manner. Her dialogues, her expressions, everything was so
                impressive and satisfying.
              </p>
              <p>
                On the next day we got an opportunity to shoot the further
                shortfilm with Veteran Actor Mohan Agashe sir. When Mohan sir
                plays a character, it looks so fine and satisfactory. As our
                Nandu Bhure sir (Acting Professor MGM Film Arts) who taught us
                that how we should switch between our characters(Original us and
                Character we are playing) very immediately as an ON/OFF switch,
                so we practically saw Mohan sir doing that flawlessly. I was
                acting as a grandson of Mohan Agashe sir where there was a scene
                of crying at that time I've used a method stated by Stanislavsky
                called as Emotional Memory. It helped me alot to get into my
                character and hold the emotions.
              </p>
              <p>
                And also it was a great experience to work with Milind Jog sir,
                the cinematographer of many hit films such as Luka-chuppi,
                Gulabjaam, Faster Fene, etc. He taught us to be creative in our
                works.
              </p>
              <p>
                It was my fortune that i've got a chance to work with Actor
                &amp; Director Chandrakant Kulkarni Sir and our HOD Shiv Kadam
                sir.
              </p>
              <p>
                I would really like to thank MGM University, MGM School of Film
                Arts, Vice Chancellor Ankushrao Kadam sir, HOD Shiv Kadam sir,
                Professor Nandu Bhure sir, Cinematographer Jaee Kadam mam and
                our whole team of MGM FILM ARTS working for the short film.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

    
    </>
  )
}
