import React, { useLayoutEffect, useRef } from "react";
import { Header } from "../../Components/Common/Header";
export const MgmFilmSociety = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../assets/imgs/bg/mgmu.jpg")}`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    MGM FILM SOCIETY
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pt-3">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <img
                  src={require("../../assets/imgs/thumb/01.jpg?v=0.1.8")}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="contact__form">
                  <h2 className="sec-title mb-5 mt-5">
                    MGMU Film Society Registration
                  </h2>
                  <form action="#" method="POST">
                    <div className="row g-3">
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="tel"
                          name="phone"
                          placeholder="Phone (Whatsapp No.)"
                          required
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="text"
                          name="college/department"
                          placeholder="College/Department"
                          required
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="text"
                          name="address"
                          placeholder="Address"
                          required
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="text"
                          name="city"
                          placeholder="City"
                          required
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row g-3">
                  <div className="col-12">
                    <div className="btn_wrapper ps-5">
                      <button className="wc-btn-primary btn-hover btn-item">
                        <span></span> Submit
                        <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <div
                    className="job__detail-content"
                    style={{ paddingTop: "0px" }}
                  >
                    <h2 className="sec-title" style={{ paddingTop: "22px" }}>
                      MGM FILM SOCIETY
                    </h2>
                    <p>
                      MGM Film Society is a closed group of film enthusiasts,
                      who either like film viewing or making. This society
                      encourages like-minded students to come together to
                      discuss and ideate about films. The Film Society of MGM
                      University stands for celebrating the freedom of
                      expression through cinema.
                    </p>
                    <ul>
                      <li>To promote artistic film-viewing</li>
                      <li>To impart understanding of films</li>
                      <li>
                        To impart the art of film appreciation and film-making
                      </li>
                      <li>
                        To provide exposure of world cinema to MGMU students
                      </li>
                    </ul>
                    <p className="fw-bold pt-3">
                      Cinema is the most popular art in the world. But
                      over-commercialization is becoming a dangerous aspect for
                      the evolution of the art of cinema. Youngsters are also
                      exposed to popular cinema only. We have seen that such
                      films have created a bad impact on audiences as far as
                      mental health is concerned.
                    </p>
                    <p className="fw-bold">
                      To the other side, the world cinema galaxy is full of
                      beautiful masterpieces made by stalwarts which will enrich
                      the viewer's life and philosophy.
                    </p>
                    <p className="fw-bold">
                      The major objective of the film society is to open that
                      galaxy for MGMU students and develop not only a good
                      audience but also an art loving citizen.
                    </p>

                    <h2>Functions</h2>
                    <ul>
                      <li>
                        Members will be able to attend several seminars arranged
                        by MGMU School of Film Arts.
                      </li>
                      <li>
                        Members will be able to attend Aurangabad International
                        Film festival as delegate or as a part of Organizing
                        Team.
                      </li>
                      <li>
                        Members will be able to have free MGM Film City and
                        Studio visits.
                      </li>
                      <li>
                        Selective members will be able to participate in Short
                        and Feature Films produced by MGMU School of Film Arts.
                      </li>
                      <li>
                        Fees for the membership of film society will 500/-
                      </li>
                    </ul>

                    <h2>Benefits to the Prime Members</h2>
                    <ul>
                      <li>
                        Members will be able to attend several seminars arranged
                        by MGMU School of Film Arts.
                      </li>
                      <li>
                        Members will be able to attend Aurangabad International
                        Film festival as delegate or as part of Organizing Team.
                      </li>
                      <li>
                        Members will be able to have free MGM Film City and
                        Studio visits
                      </li>
                      <li>
                        Selective members will be able to participate in Short
                        and Feature Films produced by MGMU School of Film Arts.
                      </li>
                      <li>
                        Premium Members will be able to attend all films and
                        documentary shows arranged for students of MGMU School
                        of Film Arts.
                      </li>
                      <li>
                        Premium Members will be able to attend Master Classes of
                        stalwarts from Film Industry organized by MGMU School of
                        Film Arts.
                      </li>
                      <li>
                        Premium Member will have a free access to several
                        production activities at MGMU School of Film Arts.
                      </li>
                      <li>
                        Premium Members will be able to participate in Film
                        Production by MGMU School of Film Arts as Actor,
                        Assistant to technicians, etc.
                      </li>
                      <li>
                        Fees for the Prime membership of film society will
                        1500/-
                      </li>
                    </ul>

                    <h2>(Rules & Regulations)</h2>
                    <ul>
                      <li>He/She should be student of MGM University</li>
                      <li>He/she should be 18 years and above </li>
                      <li>
                        Based on the students' written form, he/she will be
                        enrolled as a member of the society for one year.{" "}
                      </li>
                      <li>
                        an annual membership of Rs.500 and Prime Membership of
                        1500/- needs to be paid at the accounts section or
                        online.{" "}
                      </li>
                      <li>
                        Membership of the Film Society is non-transferable{" "}
                      </li>
                      <li>
                        The membership of the student may be cancelled if the
                        attendance/behaviour of the student is not found
                        satisfactory. The membership fees in such cases will not
                        be refunded.
                      </li>
                      <li>
                        Membership to this society is strictly on an annual
                        basis. If the members wish to renew his/her membership,
                        he/she needs to apply afresh every year which is
                        subjected to review by the committee.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
