import React from "react";
import ShortFilmVideoComponent from "./ShortFilmVideoComponent";
import Video from "../../../assets/imgs/essential-img/video-frame.png";
import Img1 from "../../../assets/imgs/brand/1.jpg";
import Img2 from "../../../assets/imgs/brand/2.jpg";
import Img3 from "../../../assets/imgs/brand/3.jpg";
import Img4 from "../../../assets/imgs/brand/4.jpg";
import Img5 from "../../../assets/imgs/brand/5.jpg";
import Img6 from "../../../assets/imgs/javed-akhtar.jpg";
import Img7 from "../../../assets/imgs/padmapani.png";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
  ImageSlider3,
  ImageSlider5,
  ImageSlider6,
  ImageSlider7,
} from "../../../Components/Common/ImageSlider";
export const ShortfilmandBrands = () => {
  return (
    <>
      {/* <!-- About area start --> */}
      <section className="about__area-7 mt-05 pt-05">
        <div className="container  ">
          <h3 className="sec-title-6 title-anim text-center">
            Our Students make <br />
            30 Short Films, 1 Feature Film Every Year
          </h3>
          <div className="single__thumb  ">
            {/* <video loop muted autoplay playsinline>
                    <source src="assets/video/video.mp4" type="video/mp4" />
                  </video> */}
            <LazyLoadComponent>
              <ShortFilmVideoComponent />
            </LazyLoadComponent>
            <img src={Video} alt="image" className="video-frame" />
          </div>
          <h4 className="sec-title-6 text-center">
            First Commercial Project Co-Produced by MGMU School of Film Arts is
            exhibited on Play TV OTT Platform
          </h4>
          <p>
            We are happy to inform you that first commercial project Chand Sanse
            Produced by Jigisha Creations (Chandrakant Kulkarni) and Co-Produced
            by MGMU School of Film Arts is exhibited on Play TV OTT. The Short
            Feature Film starring Mukta Barve, Dr. Mohan Agase and several MGMU
            School of Film Arts students is based on Priya Tendulkar's Story. It
            is directed by Pratima Joshi. The Film is entirely shot at MGM
            Campus , Aurangabad and the complete technical support, project
            execution is done by MGMU School of Film Arts with the help of
            Architecture Dept., JNEC and MGMU Institute of Fashion Design. The
            film is getting good reviews on the OTT. You can watch the film by
            clicking
            <Link to="https://www.playy.tv/video/chand-saanse-a-few-breathes?vodId=6347a5f10956cf452c10b026">
              &nbsp;here.
            </Link>
          </p>
        </div>
      </section>
      <div className="swiper testimonial__slider-4">
        <h2 class="sec-title-6 title-anim text-center">
          69th National Film Awards
        </h2>
        <div className="swiper-wrapper">
          <ImageSlider5 />
        </div>
      </div>
      <section class="mb-5">
        <div className="container">
          <h2 class="sec-title-6 title-anim text-center">
            9th Ajanta Ellora International Film Festival
          </h2>
          <div className="row gallery-slider d-flex justify-content-center">
            <ImageSlider6 />
          </div>
        </div>
      </section>
      <section class="mb-5">
        <div className="container">
          <h2 class="sec-title-6 title-anim text-center">
            PADMAPANI Lifetime Achievement Award
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 text-center">
              <img src={Img6} alt="javed akhtar" />
              <p>POET, WRITER AND LYRICIST</p>
              <h4 className="sec-title-6">Mr. Javed Akhtar</h4>
              <img className="" src={Img7} alt="padmapani award" />
            </div>
            <div className="col-lg-8 col-md-6 col-12">
              <p>
                Mr. Javed Akhtar to receive the Padmapani Lifetime Achievement
                Award An annual celebration of unique films from around India
                and the world, the 9th Ajanta-Ellora Film Festival (AIFF 2024)
                is going to be held from January 3 to 7, 2024 at Chhatrapati
                Sambhajinagar. This year, the most prestigious honour of the
                festival - the Padmapani, Lifetime Achievement Award will be
                conferred upon the renowned poet, lyricist and screenwriter,
                Javed Akhtar for his invaluable contributions to the Indian
                cinema. The declaration was made here today by Mr. Nandkishor
                Kagliwal, Chairman, AIFF organizing committee and Chief Mentor,
                Mr. Ankushrao Kadam. The Padmapani Award Selection Committee
                consisted of renowned film director, Girish Kasaravalli,
                renowned Hindi poet Mr. Ashok Vajpeyi and famous film critic,
                Ms. Latika Padgaonkar, Festival Director Ashok Rane and film
                director, Chandrakant Kulkarni. The award comprises a Padmapani
                Memento, a letter of honour and monetary reward of rupees two
                lakhs.
              </p>
              <p>
                The Padmapani Lifetime Achievement Award will be conferred upon
                Mr. Javed Akhtar during the opening ceremony of the festival on
                Wednesday, January 3, 2024 at 7.00 PM at Rukmini Auditorium, MGM
                University Campus, Chhatrapati Sambhajinagar. The ceremony will
                take place in the gracious presence of various national and
                international artists, eminent personalities from different
                walks of life and film lovers.
              </p>
              <p>
                Javed Akhtar, known as the magician of words in Hindi cinema,
                has carved a unique place of his own through his memorable
                songs, ghazals, films, music and screenplays. He along with
                screenwriter Salim Khan has written the scripts of some of the
                Bollywood's most iconic movies such as Sholay, Zanjeer and
                Deewar. Mr. Javed Akhtar was honoured with India's fourth
                highest civilian award, Padma Shri in 1999. He has also won the
                National award for 'Best Lyricist' five times and Filmfare award
                for 'Best Lyricist' seven times. He was honoured with the
                Richard Dawkins Award in 2020 for promoting democracy and
                progressive thinking.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About area end --> */}
      {/* <!-- Brand area start --> */}
      <section className="brand__area">
        <div className="container  ">
          <div className="row">
            <div className="col-xxl-12">
              <h2 className="sec-title-6 title-anim text-center ">
                Technology we use
              </h2>
              <div className="row brand__list-3 justify-content-center">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-6 brand__item-2 fade_bottom">
                  <Fade bottom duration={500}>
                    <img src={Img1} alt="Brand Logo" />
                  </Fade>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-6 brand__item-2 fade_bottom">
                  <Fade bottom duration={700}>
                    <img src={Img2} alt="Brand Logo" />
                  </Fade>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-6 brand__item-2 fade_bottom">
                  <Fade bottom duration={900}>
                    <img src={Img3} alt="Brand Logo" />
                  </Fade>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-6 brand__item-2 fade_bottom">
                  <Fade bottom duration={1100}>
                    <img src={Img4} alt="Brand Logo" />
                  </Fade>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-6 brand__item-2 fade_bottom">
                  <Fade bottom duration={1300}>
                    <img src={Img5} alt="Brand Logo" />
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Brand area end --> */}
    </>
  );
};
