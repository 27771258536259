import React from "react";
import img from "../../../assets/imgs/bg/mgmu.jpg";
import { Header } from "../../../Components/Common/Header";
import Fade from "react-reveal/Fade"; // Assuming you're using react-reveal

export const MgmUniversity = () => {
  const titleText = "About MGM University";
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    {titleText.split("").map((char, index) => (
                      <Fade key={index} duration={600} delay={index * 50}>
                        <span>{char}</span>
                      </Fade>
                    ))}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0  ">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                                <h1>Diploma In Filmmaking</h1>
                            </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-sub-title title-anim">
                    Mahatma Gandhi Mission
                  </h2>
                  <h2 className="sec-title onerem"> MGM University</h2>
                  <div className="">
                    <img
                      src={require("../../../assets/imgs/bg/mgmu2.jpg")}
                      className="img-fluid my-3"
                      alt=""
                    />
                  </div>
                  {/* <!-- <ul className="job__detail-meta">
                                        <li><span>Location</span> 2541, Austin, New York</li>
                                        <li><span>Date</span> 24, June 2022</li>
                                        <li><span>Job Type</span> Full time (Remote)</li>
                                    </ul> --> */}
                  <div className="job__detail-content">
                    <p>
                      MGM University, established by the widely revered Mahatma
                      Gandhi Mission Trust, is a self-financed State University.
                      It has the 2(f) status of the University Grants Commission
                      of India (UGC) and is approved by the Government of
                      Maharashtra. MGM School of Film Arts is part of CJMC, a
                      constituent college of MGM University from 2019. The
                      institute has an excellent infrastructure, and students
                      can access all the facilities, in the areas of sports and
                      culture, in the environs of the green, safe, and
                      eco-friendly, MGM Campus. MGM University under the aegis
                      of the Mahatma Gandhi Mission is the only self-financed
                      private university in Marathwada. It has been launched in
                      Aurangabad as a tribute to our venture of adhering to
                      Gandhian ideology and to mark the 150th birth anniversary
                      of Mahatma Gandhi. MGM University has adopted UGC's choice
                      based credit system and outcome-based curriculum framework
                      in the fields of Engineering, Commerce, Management,
                      Fashion Design, Hotel Management, Journalism & Mass
                      Communication, Photography, Film Arts, Fine Arts,
                      Performing Arts, Information Technology, Computer Science,
                      Biosciences and Biotechnology.
                    </p>
                    <h2>Why MGM University</h2>
                    <ul>
                      <li>
                        Facilitates research, sports, and other amenities at par
                        with the international standards.
                      </li>
                      <li>
                        Aims to establish 50 start-ups annually as an
                        entrepreneurial promotion activity in line with the
                        Maharashtra state government policy of Maharashtra
                        Center for Entrepreneurship Development (MCED) so that
                        the students are job creators, not job takers only.
                      </li>
                      <li>Audition assistance</li>
                      <li>
                        The only self-financed private University in Marathwada
                      </li>
                      <li>Unique and futuristic curriculums.</li>
                      <li>
                        Support provided to talented but underprivileged
                        students to become entrepreneurs.
                      </li>
                      <li>
                        Striving for global exposure and empowerment program.
                      </li>
                      <li>
                        To promote innovative solutions and research MGM has
                        initiated the earmarked 'patent mission program'.
                      </li>
                    </ul>
                    <p className="fw-bold mt-3">
                      <strong>
                        Real education consists in drawing the best out of
                        yourself. What better book can there be than the book of
                        humanity?
                      </strong>
                    </p>
                    <h2 className="text-end" style={{ marginBottom: "0px " }}>
                      -Mahatma Gandhi{" "}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
