import React from 'react'
import { Header } from "../../Components/Common/Header";

export const FeatureFilmProduction = () => {
  return (
    <>
        <Header />
    
    <main>
                <section className="job__detail-top pt-150 pb-100"
                    style={{
                        background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.2.1")})`
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="sec-title-wrapper">
                                    <h2 className="sec-title animation__char_come text-white mt-5">Feature Film Production
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="portfolio__area-3 mt-5 mb-5">
                    <div className="container">

                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="swiper portfolio__slider-3">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="portfolio__slide-3">
                                                <a href="javascript:;">
                                                    <img src={require("../../assets/imgs/portfolio/3/1.jpg?v=0.2.1")}
                                                        alt="Portfolio Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="portfolio__slide-3">
                                                <a href="javascript:;">
                                                    <img src={require("../../assets/imgs/portfolio/3/2.jpg?v=0.2.1")}
                                                        alt="Portfolio Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="portfolio__slide-3">
                                                <a href="javascript:;">
                                                    <img src={require("../../assets/imgs/portfolio/3/3.jpg?v=0.2.1")}
                                                        alt="Portfolio Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="portfolio__slide-3">
                                                <a href="javascript:;">
                                                    <img src={require("../../assets/imgs/portfolio/3/4.jpg?v=0.2.1")}
                                                        alt="Portfolio Image" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="portfolio__slide-3">
                                                <a href="javascript:;">
                                                    <img src={require("../../assets/imgs/portfolio/3/5.jpg?v=0.2.1")}
                                                        alt="Portfolio Image" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="swiper-btn">
                                        <div className="pp-prev"><i className="fa-solid fa-arrow-left"></i></div>
                                        <div className="pp-next"><i className="fa-solid fa-arrow-right"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

    </>
  )
}
