import React from "react";
import { ImageSlider3 } from "../../Components/Common/ImageSlider";
import {Header} from "../../Components/Common/Header"
export const Gallery = () => {
  return (
    <>
    <Header/>
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.2.1")})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Gallery
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">
                    Check life at MGMU School of Film Arts
                  </h2>
                </div>
                <div className="row text-white">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 mb-1">
                    <img
                      src={require("../../assets/imgs/portfolio/1/21.jpg?v=0.2.1")}
                      className="img-fluid"
                      alt
                    />
                    <h3>Lush green campus</h3>
                    <p className="pb-4">
                      MGM University Campus is lush green, safe & beautiful
                      campus. Witness it in the Anthem of our University Sung by
                      Kavita Krishnamurthy.
                    </p>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 mb-1">
                    <img
                      src={require("../../assets/imgs/portfolio/1/22.jpg?v=0.2.1")}
                      className="img-fluid"
                      alt
                    />
                    <h3>Om Raut's visit</h3>
                    <p className="pb-4">
                      Director of Tanhaji, Adipurush, Lokmanya visited MGM
                      School of Film Arts. Check the image to see his reaction
                      about us.
                    </p>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 mb-1">
                    <img
                      src={require("../../assets/imgs/portfolio/1/23.jpg?v=0.2.1")}
                      className="img-fluid"
                      alt
                    />
                    <h3>Visit by sriram Raghavan</h3>
                    <p className="pb-4">
                      Director of Andhadhun, Badlapur, Agent Vinod visited MGM
                      School of Film Arts. Check the image to see his reaction
                      about us.
                    </p>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 mb-1">
                    <img
                      src={require("../../assets/imgs/portfolio/1/24.jpg?v=0.2.1")}
                      className="img-fluid"
                      alt
                    />
                    <h3>Yateem Karyekar Masterclass</h3>
                    <p className="pb-4">
                      Yateen Karyekar, Famous for his act as Anand Bhai in
                      Munnabhai MBBS is a Mentor of Acting Dept. Click the image
                      to see the glimpses of Master Class by him.
                    </p>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 mb-1">
                    <img
                      src={require("../../assets/imgs/portfolio/1/25.jpg?v=0.2.1")}
                      className="img-fluid"
                      alt
                    />
                    <h3>Mukta barve about MSFA</h3>
                    <p className="pb-4">
                      Mukta Barve is the Mentor of Acting Dept. of MSFA. Click
                      the image to see her reaction about MSFA.
                    </p>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6 mb-1">
                    <img
                      src={require("../../assets/imgs/portfolio/1/26.jpg?v=0.2.1")}
                      className="img-fluid"
                      alt
                    />
                    <h3>Mahesh Aney masterclass</h3>
                    <p className="pb-4">
                      DOP of Swades and so many legendary Film Mahesh Aney sir
                      is the Mentor of Cinematography and Direction Dept. Click
                      the image to see glimpses of his Master Class.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row gallery-slider d-flex justify-content-center" >
              <ImageSlider3 />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
