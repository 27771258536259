import React from "react";
import { Container } from "react-bootstrap";
const VideoComponent = () => {
  return (
    <>
      <div className="video-wrapper " >
        <div className="video-container" id="player" style={{width:"100%",height:"100%"}}></div>
        <Container className="p-0 m-0 ">
          <div
            className="ratio ratio-16x9"
            style={{
              position: "absolute",
              height: "0",
              paddingBottom: "56.25%",
              width: "100%",
            }}
          >
            <iframe
              className="video-container"
              id="player"
              frameBorder="0"
              // allowfullscreen="1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              title="Admissions open for 2k23 Contact -96990 39209"
              style={{
                height: "100%",
                width: "100%",
                top: "0",
                left: "0",
                position: "absolute",
              }}
              src="https://www.youtube.com/embed/9yqtvsOr2Qc?autoplay=1&mute=1&controls=0&playsinline=1&modestbranding=1&showinfo=0&loop=1&listType=playlist&list=PLinX3b-HhXTju_P_JL4x2S-QqNIJfZK-Z&enablejsapi=1&origin=https%3A%2F%2Ffilmmaking.mgmu.ac.in&widgetid=1"
            ></iframe>
          </div>
        </Container>
      </div>
     
    </>
  );
};

export default VideoComponent;
