import React, { useLayoutEffect, useRef } from "react";
import { Header } from "../../Components/Common/Header";

export const Facilities = () => {
  const el = useRef();

  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: 'url("assets/imgs/bg/vfx.jpg?v=0.2.1")}) !important',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Facilities
                  </h2>
                  {/* <p className="text-white">
                    'State-Of-The-Art' Filmmaking Facility
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Portfolio area start --> */}
        <section className="portfolio-v5 portfolio__project">
          <div className="container text-center">
            <div className="line-3"></div>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come">
                    <span className="fst-italic pe-1">State of the art</span>{" "}
                    Facilities
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/1.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>DI Lab</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/2.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Super 35mm Cameras</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/3.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Cine Lenses</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/4.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Shooting Locations</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/5.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Shooting Floor</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/6.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Cine Lights</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/7.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>FCP Editing Studios</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/8.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Sound Studio</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/9.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>VFX & Animation Lab</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/10.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Chitrapati Dr.V. Shantaram</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/11.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Chroma floor</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/12.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>BMD pocket camera</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/17.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Swimming Pool</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/18.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Sports Club</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/19.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>Hostel</h3>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6">
                <img
                  src={require("../../assets/imgs/portfolio/1/20.jpg?v=0.2.1")}
                  className="img-fluid"
                  alt=""
                />
                <h3>90.8 radio station</h3>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Portfolio area end --> */}

        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                                <h1>Diploma In Filmmaking</h1>
                            </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <p>
                    MGM School of Film Arts is not just a Filmmaking Institute
                    but it is a complete Feature Film Production Company. MGM
                    School of Film Arts firmly believes that film is an art and
                    every student of MGM Film Arts needs to be groomed as an
                    artist first.
                  </p>
                  <p>
                    Hence, alongside top-notch mentoring from experts in the
                    film making field there are robust facilities like film
                    cameras, editing studios, sound recording studio, color lab,
                    shooting floors, shooting locations in film city are
                    provided with easy access to students of each stream.
                  </p>
                  <p className="mt-3">
                    MGM School of Film Arts is operated from two premises of
                    MGM.
                  </p>
                  <ul className="mb-4">
                    <li>
                      1 - Post Production and Admin Premises, MGM Campus, N-6,
                      CIDCO, Aurangabad
                    </li>
                    <li>
                      2 - Film City, MGM Golf Club Premises, Padegaon, Aurangaba
                    </li>
                  </ul>
                  <h2 className="sec-title">
                    Post Production &amp; Admin Premises
                  </h2>
                  {/* <!-- <ul className="job__detail-meta">
                                        <li><span>Location</span> 2541, Austin, New York</li>
                                        <li><span>Date</span> 24, June 2022</li>
                                        <li><span>Job Type</span> Full time (Remote)</li>
                                    </ul> --> */}
                  <div className="job__detail-content">
                    <ul>
                      <li>
                        Hitchcock Edit Bay 5 Independent FCP Editing Setup
                      </li>
                      <li>Da Vinci Colour Lab DI Theatre</li>
                      <li>Amadeus Studio Sound Recording & Mixing Facility</li>
                      <li>Chroma Floor Ready to shoot chroma setup</li>
                      <li>
                        Dr. V. Shantaram Theatre Preview Theatre with 4K
                        Projection, 9.2.1 Dolby Sound
                      </li>
                      <li>Animation Lab Animation & Vfx workstations</li>
                      <li>Midi Setup Music Arrangement Setup</li>
                      <li>
                        Rukmini Auditorium Theatre for Plays & Live Performances
                      </li>
                      <li>Community Radio Station 90.8 FM</li>
                      <li>
                        Sports Facility Cricket Stadium, Gym, Swimming Pools,
                        Tennis Courts, Basketball Court, etc.
                      </li>
                      <li>Library</li>
                      <li>Central Canteen Food & Beverages</li>
                      <li>Hostels For Boys & Girls</li>
                    </ul>
                    <div className="pb-30">
                      <h2>Program Overview</h2>
                      <p>
                        The Young Aspirants who wish to pursue their passion of
                        Visual effects into a career. this fruitful course is
                        specially designed with various elective specializations
                        where students can master their desired technical skills
                        along with 30% Theory and 70% Practical knowledge.
                      </p>
                      <p>
                        Students will get access to the 'State-Of-The-Art'
                        facilities as well as professional studios with world
                        class equipment and technology.
                      </p>
                    </div>
                    <div className="pb-30">
                      <h2>Filmmaking Facilities</h2>
                      <p>
                        Film City Editing Studios Sound Recording & Mixing
                        Studio Colour Lab (DI) Preview Theatre (4K, 9.2.1 Dolby)
                        Film Cameras Cine Lights Cine Lenses Chroma Floor
                        Shooting Locations Costume Library Art Inventory Vfx Lab
                        Miniature Studio
                      </p>
                    </div>
                    <h2 className="pb-30">Program Benefits</h2>
                    <ul>
                      <li>On Job Training</li>
                      <li>
                        After successful completion of this course students can
                        start working as Vfx Supervisor, Animator for Feature
                        Films, Television Serials, Web Series, Television
                        Commercials, etc.
                      </li>
                      <li>
                        Participation in Aurangabad International Film Festival.
                      </li>
                      <li>
                        Degree/Diploma/Certificate of Completion from MGM
                        University, Aurangabad
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                                <h1>Diploma In Filmmaking</h1>
                            </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Film City, MGM Golf Club Premises</h2>
                  <div className="job__detail-content">
                    <ul>
                      <li>Shooting Floor 7000 sqft., ready to shoot</li>
                      <li>Art Inventory Workshop for Art Direction</li>
                      <li>Costume Lab Dress & Props Facility</li>
                      <li>Makeup Rooms Independent for Male &amp; Female</li>
                      <li>
                        Shooting Location Range of Locations required for shoot
                      </li>
                      <li>Cinematography Lab Cine Lights storage & Workshop</li>
                      <li>Golf Club Golf Facility</li>
                      <li>Golf Canteen Food & Beverages</li>
                      <li>Backlots Empty Spaces for set construction</li>
                      <li>Miniature Studio Specialised facility for Vfx</li>
                      <li>Open Air Theatre Arena for Live Performances</li>
                      <li>Basketball Court</li>
                      <li>Tennis Court</li>
                    </ul>
                  </div>
                </div>
                <h2 className="sec-title-6">“Alfred Hitchcock Edit Bay”</h2>
                <div className="job__detail-content">
                  <p>
                    MGM Film Art's Alfred Hitchcock Edit Bay is a visual editing
                    studio comprising of five individual studios with the setup
                    of Final Cut Pro X software installed in the systems
                  </p>
                </div>
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">“Amadeus Symphony Studio”</h2>
                  <div className="job__detail-content">
                    <p>
                      For acquiring an amazing audio recording experience for
                      the students we have Amadeus Symphony Studio, a sound
                      recording studio with Protocols, Logic pro X software and
                      technical facilities such as on location sound, dubbing
                      and Foley.
                    </p>
                  </div>
                </div>
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">“Da Vinci Color Lab”</h2>
                  <div className="job__detail-content">
                    <p>
                      We have Da Vinci Color Lab with DI & Mastering facilities
                      as well as Davinci's Resolve Color Grading Panel for the
                      perfect blend of colors in the final product.
                    </p>
                  </div>
                </div>
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">“VFX & Animation Lab”</h2>
                  <div className="job__detail-content">
                    <p>
                      We have a special Lab for VFX and animation with Maya,
                      Aftereffect and 3D max software for a wonderful experience
                      in practical training.
                    </p>
                  </div>
                </div>
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">“Chitrapati Dr.V. Shantaram”</h2>
                  <div className="job__detail-content">
                    <p>
                      Preview Theatre equipped with 4k Projection and 9.2.1
                      Dolby Sound
                    </p>
                    <p>
                      For the preview of the process you've been through while
                      making your film, there has to be a perfect place that
                      will help you to spot the corrections or insert any
                      desired changes into the film. For this purpose we have
                      Chitrapati V. Shantaram Theatre, a preview theatre with 4k
                      projection and 9.2.1 Dolby sound system to enhance the
                      experience of the students in previewing their skill-full
                      practical.
                    </p>
                    <p>
                      Bergman's Courtyard is an open stage provided to the
                      students for shooting purpose. The space is well equipped
                      with green potted plants, artificial water fountains, lush
                      green and serene ambience which stands testimony to be
                      perfect outdoor scenery that will make an appealing shot
                      for the student's practical.
                    </p>
                    <p>
                      With a vast expanse of outdoor location spread across 36
                      acres of land, MGM Film City incorporates a stage with a
                      dimension of 6500 square feet constructed at Padegaon
                      along with Art Inventory which provides all the faculties
                      like carpentry, welding, molding, props, costume library
                      and well equipped green rooms.
                    </p>
                  </div>
                </div>
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">“Chroma floor”</h2>
                  <div className="job__detail-content">
                    <p>
                      A well-equipped croma studio with all the essential
                      equipments like teleprompter, microphones, cameras,
                      professional lights, editing bay and computer system are
                      available to provide hands-on training to students.“Radio
                      MGM” 90.8 MHz is an ardent and enthusiastic community
                      radio station that nurture educational fields and society
                      within 35-km radius in and around Aurangabad. It is a
                      voice of a local mode that defines itself as an autonomous
                      entity. Our radio stations broadcast essential and
                      informatory programs based on educational, social issues,
                      entertainment & cultural activities.A well maintained
                      stadium will all the amenities required in sports and
                      games is situated at our University to organize various
                      indoor, outdoor sports activities and events.
                    </p>
                    <p>
                      The master studio of MGM Department of Photography is a
                      state of the art setup with complete ready to shoot
                      facilities:
                    </p>
                    <div className="job__detail-content">
                      <ul>
                        <li>
                          D-5 Camera - To make the learning experience more
                          exciting
                        </li>
                        <li>
                          Mirror less Cameras - professional training on
                          specialized lenses
                        </li>
                        <li>
                          High-tech lenses - ultimate and technologically
                          advanced to enhance photography
                        </li>
                        <li>
                          10mm-800mm lenses - variation in selection of lenses
                          according to the requirements
                        </li>
                        <li>makeup room and professional lights</li>
                        <li>
                          Advanced Photography equipment - a diverse collection
                          of professional equipment
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
