import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
import {
  ButtonBAHonsBrochure,
  ButtonBAHonsSyllabus,
} from "../../../Components/Common/Button/Button";

export const GraduationProgram = () => {
  const el = useRef();

  return (
    <>
      <Header />
      <main>
        <section className="job__detail-top pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    Graduation Program
                  </h2>
                  <p className="text-white">
                    Learn Filmmaking in a hands-on, practical, production
                    company environment
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              <div className="text-center mt-3"></div>
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8">
                <div className="job__detail-wrapper">
                  <div className="job__detail-content">
                    <h2>Program Highlights </h2>
                    <ul>
                      <li>Compulsory Short Film Production every semester</li>
                      <li>Masterclass by Renowned Directors</li>
                      <li>
                        Feature Film Production, Release, and Marketing Process
                        compulsory for every student
                      </li>
                    </ul>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Film City Editing Studios Sound Recording &amp Mixing
                      Studio Color Lab (DI) Preview Theatre (4K, 9.2.1 Dolby)
                      Film Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory VFX Lab Miniature
                      Studio
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4">
                <div className="job__detail-sidebar">
                  <h5 className="mb-3">Speak with Program In-charge</h5>
                  <ul>
                    <li>
                      <span>Prof. Jaee Kadam</span> +91 8208881783
                    </li>
                  </ul>
                  <h5 className="mt-5 mb-3">Fees Structure</h5>
                  <ul>
                    <li>
                      <span>Duration:</span> 3 Years (6 Semesters)
                    </li>
                    <li>
                      <span>Annual Fees:</span> 1,50,000/- + University Fees
                    </li>
                    <li>
                      <Link to="#" target="_blank">
                        View More
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5 pt-30 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
                <h2 className="sec-title animation__char_come">
                  BA (Hons) In Filmmaking
                </h2>
              </div>
              <div className="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                <div className="sec-text">
                  <p>
                    Qualify with a BA (Hons) in Filmmaking in 3 years. Our
                    intensive, full-time program covers all the key disciplines
                    of filmmaking.
                  </p>
                </div>
              </div>
              <p className="mb-3 mt-3">
                Students can opt for one Major out of the following
              </p>
            </div>
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-acting-for-films">
                      <h3 className="ps-title">
                        BA (Hon.) <br /> Film Acting
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-film-direction">
                      <h3 className="ps-title">
                        BA (Hon.) <br /> Film Direction
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-film-editing">
                      <h3 className="ps-title">
                        BA (Hon.) <br />
                        Film Editing
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-sound-design-music-production">
                      <h3 className="ps-title">
                        B.A (Hon.) Sound <br />
                        &amp; MUSIC PRODUCTION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-cinematography">
                      <h3 className="ps-title">
                        BA (Hon.) <br /> Cinematography
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-vfx-animation">
                      <h3 className="ps-title">
                        BA (Hon.) <br />
                        VFX &amp; ANIMATION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-production-design-art-direction">
                      <h3 className="ps-title">
                        BA (Hon.) in PRODUCTION <br /> &amp; ART DIRECTION
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                  <ButtonBAHonsBrochure />
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <ButtonBAHonsSyllabus />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
