import React, { useLayoutEffect, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../Components/Common/Header";

import img from "../../assets/imgs/bg/film-production.jpg";
export const FilmProduction = () => {
  const wcTiltElements = useRef([]);
  useLayoutEffect(() => {
    // Apply tilt effect to elements with class "wc-tilt-2"
    wcTiltElements.current.forEach((element) => {
      const handleTilt = (event) => {
        const boundingRect = element.getBoundingClientRect();
        const x = event.clientX - boundingRect.left;
        const y = event.clientY - boundingRect.top;
        const width = boundingRect.width;
        const height = boundingRect.height;
        const tiltAmount = 15; // Maximum tilt rotation in degrees
        // Calculate tilt angles based on mouse position
        const tiltX = (y / height - 0.5) * tiltAmount;
        const tiltY = (x / width - 0.5) * tiltAmount;
        // Apply CSS transform to create the tilt effect
        element.style.transform = `perspective(1000px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;
      };

      element.addEventListener("mousemove", handleTilt);
      element.addEventListener("mouseout", () => {
        element.style.transform = ""; // Reset the transform when mouse leaves
      });
    });
  }, []);
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          // style={{
          //   background:
          //     "url(assets/imgs/bg/film-production.jpg?v=0.1.4) !important",
          // }}
          style={{
            background: `url(${img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Film Productions
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="showcase__area-4">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="showcase4 wc-tilt-2 fade_bottom_3"
                  ref={(el) => (wcTiltElements.current[1] = el)}
                >
                  <Link to="/short-film-production">
                    <div className="showcase4__thumb">
                      <img
                        src={require("../../assets/imgs/portfolio/portfolio-home-4/portfolio-01.jpg?v=0.1.4")}
                        alt=""
                        data-speed="auto"
                      />
                    </div>
                    <div className="showcase4__content">
                      <div className="showcase4__content__left">
                        <h2>
                          Short Film <br />
                          <span>Production</span>
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="showcase4 wc-tilt-2 fade_bottom_3"
                  ref={(el) => (wcTiltElements.current[2] = el)}
                >
                  <Link to="/feature-film-production">
                    <div className="showcase4__thumb">
                      <img
                        src={require("../../assets/imgs/portfolio/portfolio-home-4/12.jpg?v=0.1.4")}
                        alt=""
                        data-speed="auto"
                      />
                    </div>
                    <div className="showcase4__content">
                      <div className="showcase4__content__left">
                        <h2>
                          Feature Film <br />
                          <span>Production</span>
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="showcase4 wc-tilt-2 fade_bottom_3"
                  ref={(el) => (wcTiltElements.current[0] = el)}
                >
                  <Link to="/music-production">
                    <div className="showcase4__thumb">
                      <img
                        src={require("../../assets/imgs/portfolio/portfolio-home-4/portfolio-02.jpg?v=0.1.4")}
                        alt=""
                        data-speed="auto"
                      />
                    </div>
                    <div className="showcase4__content">
                      <div className="showcase4__content__left">
                        <h2>
                          Music <br />
                          <span>Production</span>
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="showcase4 wc-tilt-2 fade_bottom_3"
                  ref={(el) => (wcTiltElements.current[3] = el)}
                >
                  <Link to="/professional-projects">
                    <div className="showcase4__thumb">
                      <img
                        src={require("../../assets/imgs/portfolio/portfolio-home-4/portfolio-05.jpg?v=0.1.4")}
                        alt=""
                        data-speed="auto"
                      />
                    </div>
                    <div className="showcase4__content">
                      <div className="showcase4__content__left">
                        <h2>
                          Professional <br />
                          <span>Projects</span>
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
