import React from "react";
import Img1 from "../../../assets/imgs/portfolio/1/1.jpg";
import Img2 from "../../../assets/imgs/portfolio/1/2.jpg";
import Img3 from "../../../assets/imgs/portfolio/1/3.jpg";
import Img4 from "../../../assets/imgs/portfolio/1/4.jpg";
import Img5 from "../../../assets/imgs/portfolio/1/5.jpg";
import Img6 from "../../../assets/imgs/portfolio/1/6.jpg";
import Img7 from "../../../assets/imgs/portfolio/1/7.jpg";
import Img8 from "../../../assets/imgs/portfolio/1/8.jpg";
import Img9 from "../../../assets/imgs/portfolio/1/9.jpg";
import Img10 from "../../../assets/imgs/portfolio/1/10.jpg";
import Img11 from "../../../assets/imgs/portfolio/1/11.jpg";
import Img12 from "../../../assets/imgs/portfolio/1/12.jpg";
import Img17 from "../../../assets/imgs/portfolio/1/17.jpg";
import Img18 from "../../../assets/imgs/portfolio/1/18.jpg";
import Img19 from "../../../assets/imgs/portfolio/1/19.jpg";
import placeholderImage from "../../../assets/imgs/portfolio/placeholder.jpg";
import Img20 from "../../../assets/imgs/portfolio/1/20.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Reveal } from "react-reveal";
import { Display } from "react-bootstrap-icons";

export const StateofArtFacilities = () => {
  const facilities = [
    { imgSrc: Img1, title: "DI Lab" },
    { imgSrc: Img2, title: "Super 35mm Cameras" },
    { imgSrc: Img3, title: "Cine Lenses" },
    { imgSrc: Img4, title: "Shooting Locations" },
    { imgSrc: Img5, title: "Shooting Floor" },
    { imgSrc: Img6, title: "Cine Lights" },
    { imgSrc: Img7, title: "FCP Editing Studios" },
    { imgSrc: Img8, title: "Sound Studio" },
    { imgSrc: Img9, title: "VFX & Animation Lab" },
    { imgSrc: Img10, title: "Chitrapati Dr.V. Shantaram" },
    { imgSrc: Img11, title: "Chroma floor" },
    { imgSrc: Img12, title: "BMD pocket camera" },
    { imgSrc: Img17, title: "Swimming Pool" },
    { imgSrc: Img18, title: "Sports Club" },
    { imgSrc: Img19, title: "Hostel" },
    { imgSrc: Img20, title: "90.8 radio station" },
  ];

  return (
    <>
      {/* <!-- Portfolio area start --> */}
      <section className="portfolio-v5 portfolio__project">
        <div className="container text-center">
          <div className="line-3"></div>
          <div className="row pt-1 pb-1">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="sec-title-wrapper">
                <h2 className="sec-title sub-section-title animation__char_come">
                  <Reveal bottom>
                    <span className="fst-italic pe-2">'State-of-the-art'</span>
                    <span>Facilities</span>
                  </Reveal>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            {facilities.map((facility, index) => (
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-6"
                key={index}
              >
                <LazyLoadImage
                  src={facility.imgSrc}
                  placeholderSrc={placeholderImage}
                  className="img-fluid"
                  alt=""
                />
                <h3>{facility.title}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- Portfolio area end --> */}
    </>
  );
};
