import React, { useState } from "react";
import { Header } from "../../Components/Common/Header";
import bgImage from "../../assets/imgs/bg/4.jpg?v=0.1.8";
{/* /api/Filmarts/saveWorkshopScholarshipRequest/ */}
      {/* {type: 1, RequestTypeId: "2", FirstName: "Test123", LastName: "Test845", Email: "testb@test.test",…}
Email:"testb@test.test"
FirstName: "Test123"
Gender: "2"
LastName: "Test845"
Phone: "0009637000"
ProgramName: "Audition"
RequestTypeId: "2"
type: 1 */}
export const Scholarship = () => {
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Phone: "", 
    Email: "",
    Gender: "",
    ProgramName: "",
    RequestTypeId: "2",
    type: 2,
  });

  const {
    FirstName,
    LastName,
    Phone,
    Email,
    Gender,
    ProgramName,
    RequestTypeId,
    type,
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create payload
    const payload = { ...formData };
    console.log(payload);
    // Make POST request to random API
    fetch(
      process.env.REACT_APP_SEND_URL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Form submitted successfully!");
        console.log(data); // Response from the API
        // Show success message to the user
        alert("Form submitted successfully!");
        // Reset form fields
        setFormData({
          FirstName: "",
          LastName: "",
          Phone: "",
          Email: "",
          Gender: "",
          ProgramName: "",
          RequestTypeId: "2",
          type: 2,
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <>
      <Header />
      <main>
        <section
          id="contact"
          className="form mt-5"
          style={{
            background: `url(${require("../../assets/imgs/bg/4.jpg?v=0.1.8")})`,
          }}
        >
          <div className="container pt-150 pb-30">
            <div className="row">
              <div className="col-xl-7 col-lg-6"></div>
              <div className="col-xl-5 col-lg-6 col-md-8 pb-3">
                <div className="ha-enquiry-block" id="dvEnquiryBlock">
                  <div className="ha-enquiry-block-inner">
                    <h1 className="ha-enquiry-block-heading">
                      Apply For Scholarship
                    </h1>
                    <form
                      className="ha-enquiry-form animate__animated animate__fadeInUp"
                      id="workshopScholarshipForm"
                      onSubmit={handleSubmit}
                      data-parsley-validate
                    >
                      <input type="hidden" id="txtRequestTypeId" value="1" />
                      <div id="formResponse"></div>
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtEF1Name"
                              name="FirstName"
                              placeholder="First name"
                              data-parsley-minlength="3"
                              minLength="3"
                              data-parsley-maxlength="60"
                              maxLength="60"
                              data-parsley-error-message="Please enter a valid name"
                              required
                              style={{ textTransform: "capitalize" }}
                              value={FirstName}
                              onChange={handleChange}
                            />
                            <label htmlFor="txtEF1Name">First Name</label>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="txtEF2Name"
                              name="LastName"
                              placeholder="Last name"
                              data-parsley-minlength="3"
                              minLength="3"
                              data-parsley-maxlength="60"
                              maxLength="60"
                              data-parsley-error-message="Please enter a valid name"
                              required
                              style={{ textTransform: "capitalize" }}
                              value={LastName}
                              onChange={handleChange}
                            />
                            <label htmlFor="txtEF2Name">Last Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="tel"
                          className="form-control txtEFPhone"
                          id="txtEF1Phone"
                          name="Phone"
                          placeholder="My Phone no."
                          minLength="10"
                          maxLength="10"
                          required
                          value={Phone}
                          onChange={handleChange}
                        />
                        <label htmlFor="txtEF1Phone">My Phone no.</label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="Email"
                          className="form-control"
                          id="txtEF1Email"
                          name="Email"
                          placeholder="My Email address"
                          minLength="3"
                          maxLength="60"
                          required
                          value={Email}
                          onChange={handleChange}
                        />
                        <label htmlFor="txtEF1Email">My Email address</label>
                      </div>
                      <div className="form-floating mb-3">
                        <select
                          className="form-select ddlGender"
                          id="ddlGender"
                          name="Gender"
                          required
                          value={Gender}
                          onChange={handleChange}
                        >
                          <option value="" hidden selected>
                            Gender
                          </option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Other</option>
                        </select>
                      </div>
                      <div className="form-floating mb-3">
                        <select
                          className="form-select ddlProgramName"
                          id="ddlProgramName"
                          name="ProgramName"
                          required
                          value={ProgramName}
                          onChange={handleChange}
                        >
                          <option value="" hidden selected>
                            Specialization
                          </option>
                          <option value="Acting For Films">
                            Acting For Films
                          </option>
                          <option value="Film Editing & DI">
                            Film Editing &amp; DI
                          </option>
                          <option value="Film Direction & Screenwriting">
                            Film Direction &amp; Screenwriting
                          </option>
                          <option value="Production Design & Art Direction">
                            Production Design &amp; Art Direction
                          </option>
                          <option value="Cinematography & DI">
                            Cinematography &amp; DI
                          </option>
                          <option value="VFX & Animation">
                            VFX &amp; Animation
                          </option>
                          <option value="Sound Design & Music Production">
                            Sound Design &amp; Music Production
                          </option>
                        </select>
                      </div>
                      <div className="d-grid gap-2 mb-3">
                        <button
                          type="submit"
                          className="btn btn-theme-primary btn-form-submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
