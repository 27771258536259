import React from "react";
import { ButtonAudition } from "../../Components/Common/Button/Button";
import { Header } from "../../Components/Common/Header";

export const Audition = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: "url(assets/imgs/bg/audition.jpg?v=0.2.1) !important",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Audition
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pt-3">
            <div className="row">
              {/* <!-- <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <img src="assets/imgs/thumb/audition.jpg?v=0.2.1" className="img-fluid" alt="" />
              </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Note</h2>
                  <div className="job__detail-content">
                    <ul>
                      <li>
                        Times slot and audition script will be sent through to
                        each actor via Email or WhatsApp.
                      </li>
                      <li>This audition is free.</li>
                      <li>
                        Information about the participating actors, audition
                        clips will be archived and made available free of cost
                        for other future plays.
                      </li>
                      <li>
                        No one has been appointed as an agent or coordinator for
                        this audition.
                      </li>
                      <li>
                        Every actor registered on the website will be given an
                        audition time.
                      </li>
                      <li>Please do not call anyone related.</li>
                    </ul>
                  </div>
                </div>
                <div className="contact__form">
                  <h2 className="sec-title mb-5 mt-5">Audition Form</h2>
                  <form action="/audition" method="POST">
                    <div className="row g-3">
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input type="tel" name="phone" placeholder="Phone" />
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-12">
                        <input
                          type="number"
                          name="age"
                          placeholder="Age"
                          min="22"
                          max="65"
                          required
                        />
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-12">
                        <textarea
                          name="experience"
                          placeholder="Experience"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="btn_wrapper ps-5">
                          <button className="wc-btn-primary btn-hover btn-item">
                            <span></span> Send <br />
                            Message
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
