import React from 'react'
import { Header } from '../../Components/Common/Header'

export const Placements = () => {
  return (
    <>
    <Header />
    <main>
                    <section className="job__detail-top pt-150 pb-100"
                        style={{
                          background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.2.1")})`
                        }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="sec-title-wrapper">
                                        <h2 className="sec-title animation__char_come text-white mt-5">Placements</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="job__detail">
                        <div className="container g-0">

                            <div className="row">
                               
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="job__detail-wrapper">
                                        <p className="mb-3">Students who have completed their programs successfully as well as
                                            the current
                                            batch are also doing internship, professional assignments at TMT Productions,
                                            Navdeep Kalamandir and assisting several film professionals like directors,
                                            cinematographers, sound designers, etc.</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

    
    </>
  )
}
