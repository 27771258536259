import React, { useLayoutEffect, useRef } from "react";
import { Header } from "../../Components/Common/Header";
import { ImageSlider6 } from "../../Components/Common/ImageSlider";

export const FilmFestivalAttendance = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.0.4")}`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    Film Festival Attendance
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 mb-5">
            <div className="container">
              <h2 class="sec-title-6 title-anim mt-3 text-center">
                9th Ajanta Ellora International Film Festival
              </h2>
              <div className="row gallery-slider d-flex justify-content-center">
                <ImageSlider6 />
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  {/* <h2 className="sec-sub-title title-anim">
                    Ajanta-Ellora International Film Festival
                  </h2> */}
                  <h2 className="sec-title">MGM University</h2>
                  <div
                    className="job__detail-content"
                    style={{ paddingTop: "0" }}
                  >
                    <p>
                      The Aurangabad International Film Festival (AIFF) is
                      organized every year in the month of February by MGM
                      Department of Film Arts, NATH Group and Aurangabad
                      Regional Center of YashwantraoChavanPrathishthan.
                    </p>
                    <p>
                      The festival aims to showcase the best of films produced
                      recently and formerly, in India and other parts of the
                      world, to the city of Aurangabad and its film buffs. This
                      festival is a platform for aspiring filmmakers,
                      technicians, artists and young film enthusiasts to
                      encourage them to learn and understand artistic as well as
                      technical aspects of cinema. This festival also aims to
                      become a medium to project Aurangabad and Marathwada
                      internationally; as the city and region provides many
                      opportunities to the film production hub, cultural center
                      in state of Maharashtra.
                    </p>
                    <p>
                      AIFF aspires to promote tourism of the region among Indian
                      and international tourists and to create a networking
                      Centre for local talents from Marathwada region to connect
                      with filmmakers, film scholars, technicians from India and
                      abroad. AIFF also aims to highlight and promote Marathi
                      cinema nationally and globally.
                    </p>
                    <h3 className="mt-3">Indian Film Competition : </h3>
                    <p>
                      7th AIFF was organized for five days, with variety of
                      programmes catered to its audience. The important part of
                      festival programme was the competitive section of
                      contemporary Indian feature-fiction films. Nine films from
                      different Indian languages competed for many awards
                      including the top prize of Best Film which carries a cash
                      prize of Rs. 1 lakh! Besides this there were award
                      categories of Best Director, Best Screenplay, Best
                      Cinematographer, Best Editor, Best Sound, Best Music, Best
                      Actor (Female) and Best Actor (Male).
                    </p>
                    <p>
                      The competition was adjudged by an international jury.
                      This jury was headed by acclaimed and award winning
                      filmmaker Sunil Sukthankar. Other jury members were film
                      critic and secretary of FIPRESCI-India PremendraMazumder
                      (Kolkata), film producer and festival director Jitendra
                      Mishra (New Delhi), Composer, Singer Songwriter & Concert
                      Pianist Ariane Gray Hubert (France), actor NanduMadhav
                      (Aurangabad).
                    </p>

                    <h3 className="mt-3">FIPRESCI-INDIA Jury Award :</h3>
                    <p>
                      FIPRESCI International is an international federation of
                      film critics. FIPRESCI-India is the association of Indian
                      film critics and accredited to FIPRESCI International.
                      This year AIFF hosted a 3-members FIPRESCI-India jury, who
                      decided their prize for best debut film of directors from
                      Indian and International films made in 2019. V. K. Joseph
                      wasbe chairperson of this jury, joined by Namrata Joshi
                      and Rekha Deshpande as other members.
                    </p>

                    <h3 className="mt-3">Opening Ceremony :</h3>
                    <p>
                      The 7th Aurangabad International Film Festival was
                      inaugurated on 5th February, 2020 in the presence of film
                      fraternity and film enthusiasts followed by the screening
                      of the Opening Film 'Hellaro' (India / Gujarati) by
                      Abhishek Shah, the Golden Lotus National Film Awardee
                      2019. The prominent personalities attending the opening
                      ceremony were popular Hindi filmmaker SriramRaghavan,
                      filmmaker Abhishek Shah, actor-director Prasad Oak,
                      actor-writer ChinmayMandlekar, filmmaker Om Raut,
                      filmmaker DigpalLanjekar, artistic director Chandrakant
                      Kulkarni, festival director Ashok Rane and many others.
                    </p>

                    <h3 className="mt-3">
                      Closing Ceremony, Lifetime Achievement Award & Awards
                      Function :
                    </h3>
                    <p>
                      The closing ceremony was held on evening of 09th February,
                      2020 wherein awards in Indian Competition, FIPRESCI-India
                      Jury Prize, Audience Choice Award and Short Film
                      Competition were announced and presented to the winners.
                    </p>
                    <p>
                      {" "}
                      The Closing Film was an acclaimed film of 2019 and Oscar
                      nominee, 'Parasite' (South Korea) directed by Bong Joon
                      Ho.
                    </p>

                    <h3 className="mt-3">Short Film Competition :</h3>
                    <p>
                      An exclusive competition of short films for the upcoming
                      filmmakers of the Marathwada region of Maharashtra, of
                      which Aurangabad is the major city was organized during
                      the 7th AIFF. In this section 10 films were shown and
                      winner was announced during the closing ceremony.
                    </p>

                    <h3 className="mt-3">Master Class & Seminar :</h3>
                    <p className="mt-3">
                      Besides the film screenings, festival also organized other
                      academic activities during these four days.
                    </p>
                    <p className="mt-3">
                      A Master Class by SriramRaghavan was held on Thursday 06th
                      February at INOX. Filmmaker DnyaneshZoting was the
                      moderator for the class.
                    </p>
                    <p className="mt-3">
                      A Seminar on topic of 'इतिहासवसिनेमॅटिकलिबर्टी' (History
                      And Cinematic Liberty) was also held on Thursday 06th
                      February at INOX, with panelists filmmaker Om Raut,
                      filmmaker DigpalLanjekar, actor-director Prasad Oak, and
                      actor-writer ChinmayMandlekar as the moderator.
                    </p>
                    <p className="mt-3">
                      An Audio-Visual Lecture on topic of of
                      'महाराष्ट्राची६०वर्षेआणिमराठीसिनेमा' (60 Years of
                      Maharashtra State and Marathi Cinema) was conducted by
                      director of Aashay Film Club Satish Jakatdar on Friday
                      08th February 2020.
                    </p>
                    <p className="mt-3">
                      A Seminar on topic of 'स्त्रीदिग्दर्शक:
                      जाणिवाआणिदृष्टिकोन' (Female filmmaker: Understandings &
                      Perspectives) was held on Saturday 08th February at INOX,
                      with filmmaker panelists Gauri Shinde, Sumitra Bhave,
                      Mrunal Kulkarni and Pratima Joshi, and actor MuktaBarve as
                      the moderator.
                    </p>

                    <h3 className="mt-3">Poster Exhibition :</h3>
                    <p>
                      This year the Poster Exhibition was dedicated to three
                      legendary film personalities - Mrinal Sen,
                      Dr.ShriramLagu,Dr. Girish Karnad. It was open atProzone
                      Mall from 1st to 9th February 2020.
                    </p>

                    <h3 className="mt-3">Film Appreciation Workshops :</h3>
                    <p>
                      The festival organizers also conducted film appreciation
                      workshops in ten selected colleges at Aurangabad and
                      encouraged students from colleges in the city and the
                      surrounding places to participate in the festival by
                      registering as delegates
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
