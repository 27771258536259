import React from "react";
import { Header } from "../../Components/Common/Header";

export const Masterclass = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.2.1")})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Masterclass
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              {/* <div className="text-center mt-5">
                          <h1>Diploma In Filmmaking</h1>
                      </div> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  {/* <h2 className="sec-title">MGM University</h2> */}
                  {/* <ul className="job__detail-meta">
                                  <li><span>Location</span> 2541, Austin, New York</li>
                                  <li><span>Date</span> 24, June 2022</li>
                                  <li><span>Job Type</span> Full time (Remote)</li>
                              </ul> */}
                  <div
                    className="job__detail-content"
                    style={{ paddingTop: "0 !important" }}
                  >
                    <p>
                      Master Classes are the one of very important activities in
                      MGM Film Art. here we introduce our students with the
                      masters and experts from the industry, who not only
                      interact with our students but also work with them on
                      their projects to give them hands on experience of working
                      with the industry standards. This is a very unique feature
                      of our innovative teaching methodology as usually in
                      masterclasses the expert answer the questionnaire prepared
                      by the curator of the class but here in MGM Film Arts
                      Department Student work on ground with the industry
                      experts and understanding realize the on ground work
                      scenario which help them improve their abilities and
                      skills related to their respected work area.
                    </p>
                    <p>
                      Interactions with the directors like Hansal Mehta and
                      ShreeramRaghavan made our students understand the
                      importance of through technical knowledge along with the
                      creative process involved in the development of the film
                      from conceptualization to actual shoot and till the
                      exhibition of the final print.During the Masterclass with
                      Mahesh Aney sir students learn to work and assist the work
                      flow of a DOP [ director of photography] and shoot few
                      scenes from department’s upcoming projects. this is how
                      students from all the faculties of film making got the
                      hands-on experience to work in the professional work
                      environment.”Film Acting Masterclass by Yateen Karyekar”
                      In the masterclass with YateenKaryekar students understand
                      the process of an Actor during preparation of his/her
                      role, and how one need to research and work on the
                      different aspects of the preparation of the character.
                    </p>
                    <p />
                    <h2 style={{ paddingTop: 22 }}>
                      “Music production Masterclass by Vishwajeet Joshi
                    </h2>
                    <p>
                      ” Vishwajeet Joshi conducted his masterclass along with
                      singer Maithili, they both took our students through the
                      detailed process of Song composition and its crucial
                      technical as well as creative aspects this workshop help
                      our students to understand the intense creative and
                      technical process of music production.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
