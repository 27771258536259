import React from 'react'
import { Header } from '../../Components/Common/Header'

export const FtiiCourses = () => {
  return (
    <>
    <Header />
    <main>
                <section className="job__detail-top pt-150 pb-100"
                    style={{background: "url(assets/imgs/bg/mgmu.jpg?v=0.2.1) !important;"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="sec-title-wrapper">
                                    <h2 className="sec-title animation__char_come text-white mt-5">Film Appreciation Course
                                        (Aurangabad)</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job__detail">
                    <div className="container g-0">

                        <div className="row">
                           
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                <div className="job__detail-wrapper">
                                    <h2 className="sec-sub-title title-anim">Film Appreciation Course</h2>
                                    <h2 className="sec-sub-title ">MGM Film Arts,</h2>
                                    <h2 className="sec-title">MGM University Campus, Aurangabad</h2>
                                    
                                    <div className="job__detail-content" style={{PaddingTop: "0 !important;"}}>
                                        <p>29th March to 02nd April 2020</p>
                                        <p>Film and Television Institute of India (FTII) Pune, under its Skilling India
                                            in Film & Television (SKIFT) initiative, in association with Mahatma Gandhi
                                            Mission (MGM) University, Aurangabad announces Short Course in Film
                                            Appreciation (05 days) on MGM University campus, Aurangabad, Maharashtra.
                                        </p>
                                        <p></p>
                                        <h2 style={{PaddingTop: "22px;"}}>Details of Course</h2>
                                        <ul>
                                            <li>29th March to 02nd April 2020 (05 days inclusive of Saturday & Sunday)
                                            </li>
                                            <li>Timings: 10 am to 5.30 pm</li>
                                            <li>No. of participants: 100 (Course will be conducted only if minimum
                                                strength of 75 is reached)</li>
                                            <li>Age: Participants must be 18 years or above in age as on 01st March 2020
                                            </li>
                                            <li>Nationality: Indian</li>
                                            <li>Educational Qualifications: HSC (12th pass), In exceptional case, 10th
                                                pass may be considered.</li>
                                            <li>Venue: MGM Film Arts, MGM University campus, Aurangabad, Maharashtra
                                            </li>
                                            <li>Fees: Rs 4,088/- per participant (Base fee = Rs 3,464/- and GST = Rs
                                                624/-)</li>
                                            <li>Mode of payment: Demand Draft to be drawn in favour of “Accounts
                                                Officer, Film & TV Institute of India, Pune”, and must be payable at
                                                Pune.</li>
                                            <li>Last date to apply: 12th March 2020 by 4 pm</li>
                                            <li>Course Director (Main Faculty) : Mr. Sudipto Acharyya
                                                Sudipto Acharyya has studied cinema at FTII Pune, with specialization in
                                                Direction and Screenplay Writing (1997 - 2000). He has completed his MA
                                                in Film Studies from Jadavpur University, Kolkata.</li>

                                        </ul>
                                        <h2 style={{PaddingTop: "22px;"}}>How to apply</h2>
                                        <p>Download the application form from FTII website (www.ftii.ac.in/p/skift)</p>
                                        <p>OR and www.mgmfilmarts.com.</p>
                                        <p>Fill - in the details, attach required Demand Draft and speed post or deliver
                                            it in person to</p>
                                        <p>Dr. Rekha Shelke, Principal, MGM College of Journalism & Mass Communication,
                                            Mahatma Gandhi Mission Campus, N - 6, CIDCO, Aurangabad - 431003,
                                            Maharashtra</p>
                                        <p>so as to reach on or before 03rd March 2020 by 4 pm</p>
                                        <p>On envelope of application at top left, write “Application for Film
                                            Appreciation Course”. Incomplete applications & applications received after
                                            due date & time will be rejected</p>

                                        <ul>
                                            <li>Selection Criteria: First - Come - First - Served basis. However, course
                                                will be conducted only if the minimum strength of 75 participants is
                                                reached. Selected candidates would be communicated by email. Course fees
                                                of the selected candidates will be non - refundable under any
                                                circumstances. Demand Drafts of non-selected candidates would be
                                                returned within 04 days.</li>
                                            <li>Medium of Instructions: English & Hindi.</li>
                                            <li>
                                                <p>Accommodation: Limited Non AC accommodation (on first come first
                                                    served basis) within the MGM University Campus, Aurangabad, on
                                                    triple sharing basis is available for the outstation participants on
                                                    additional lumpsum payment of Rs 2000/- per person for 06 days which
                                                    is not inclusive of meals. This additional payment is to be made in
                                                    advance in cash on 28th March 2020 directly to the MGM University
                                                    authorities.</p>
                                                <p>Interested participants need to intimate the accommodation
                                                    requirement in the application form without which the same will not
                                                    be considered. The accommodation will be available from 28th March
                                                    to 03rd April 2020. If any other services like laundry etc are
                                                    available & opted by the participants, the same will be charged on
                                                    at actual basis by the University to the participants.
                                                    Participants need to assure general decency, decorum etc on the
                                                    University campus and room allotment may be cancelled if same is not
                                                    followed. All rights in this regard are reserved with the University
                                                    management.</p>
                                            </li>
                                            <li>Certificates: All participants would be given participation certificate
                                                on successful completion of the course. Minimum 90% attendance is
                                                mandatory.</li>
                                        </ul>

                                        <h2 style={{PaddingTop: "22px;"}}>Queries: For any queries contact following
                                            persons;</h2>
                                        <ul>
                                            <li>From FTII, Pune - Prof. Sandeep Shahare: 9923242654, ftiiskift@gmail.com
                                            </li>
                                            <li> From MGM University - Mr. Shiv Kadam: 9823745745 (MGM Film Arts)</li>
                                        </ul>

                                        <h2 style={{PaddingTop: "22px;"}}>Dr. Rekha Shelke: 0240 - 2480490 / 0240 -
                                            2478908/ 8806255507</h2>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
    
    </>
  )
}
