import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Bounce } from "react-reveal";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";

export const FilmDirectionScreenwriting = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background:
              "url(assets/imgs/specilization/acting.jpg?v=0.0.4) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Film Direction & Screenwriting
                  </h2>
                  <p className="text-white">
                    Be the creator of stories, sights & sounds that passes into
                    our conscience
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                            <h1>Diploma In Filmmaking</h1>
                        </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Program Intro</h2>
                  {/* <!-- <ul className="job__detail-meta">
                                    <li><span>Location</span> 2541, Austin, New York</li>
                                    <li><span>Date</span> 24, June 2022</li>
                                    <li><span>Job Type</span> Full time (Remote)</li>
                                </ul> --> */}
                  <div className="job__detail-content">
                    <p>
                      A ﬁlm director controls a ﬁlm's artistic and dramatic
                      aspects and visualizes the screenplay (or script) while
                      guiding the technical crew and actors in the fulﬁlment of
                      that vision. The director has a key role in choosing the
                      cast members, production design, and all the creative
                      aspects of ﬁlmmaking. The director is viewed as the author
                      of the ﬁlm.
                    </p>
                    <h2>Program Overview</h2>
                    <p>
                      Designed for the Young Aspirants who wish to pursue their
                      passion of Direction into a career. These programs/courses
                      comprise various elective specializations where students
                      can master their desired technical skills along with 30%
                      Theory and 70% Practical knowledge Students will have
                      access to the 'State-Of-The-Art' facilities as well as
                      professional studios with world-class equipment and
                      technology for making their Feature Film Projects.
                    </p>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Film City Editing Studios Sound Recording & Mixing Studio
                      Color Lab (DI) Preview Theatre (4K, 9.2.1 Dolby) Film
                      Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory VFX Lab Miniature
                      Studio{" "}
                    </p>
                    <h2 className="mb-3">Program Benefits </h2>
                    <ul>
                      <li>On Job Training</li>
                      <li>
                        After successful completion of this course, students
                        will be able to direct a short film and also start
                        working as Assistant Director for Feature Films,
                        Television Serials, Web Series, Television Commercials,
                        etc.
                      </li>
                      <li>
                        Participation in Aurangabad International Film Festival.
                      </li>
                      <li>
                        Certificate of Completion from MGM University,
                        Aurangabad
                      </li>
                      <li>Opportunity to be an MGMU Alumni</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5">
          <div className="container">
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-film-direction">
                      <h3 className="ps-title">
                        MA in Film <br /> Direction
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-film-direction">
                      <h3 className="ps-title">
                        BA (HONS) in <br /> Film Direction
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-film-direction">
                      <h3 className="ps-title">
                        Diploma in Film <br /> Direction
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/short-programs">
                      <h3 className="ps-title">
                        Short Programs <br /> (6 Months)
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                  <div
                    className="btn_wrapper"
                    style={{ position: "relative", alignItems: "flex-start" }}
                  >
                    <Bounce top duration={1200}>
                      <Link
                        to="#"
                        target="_blank"
                        className="wc-btn-primary btn-hover btn-item"
                      >
                        <span></span>
                        Download
                        <br /> brochure
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </Bounce>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
