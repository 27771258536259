import Reveal from "react-reveal";
import React from "react";
import { ImageSlider2 } from "../../../Components/Common/ImageSlider";
export const Testimonials = () => {
  return (
    <>
      {" "}
      {/* <!-- Portfolio area start --> */}
      <section className="testimonial__area-4">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="testimonial__sec-title text-anim">
                <h2 className="sec-subtile-6 pb-0">Student's Achievements</h2>
                <h3 className="sec-title-6 title-anim">
                  <Reveal bottom>Few Of MGM Film Arts Success Stories</Reveal>
                </h3>
              </div>
            </div>
            <div className="swiper testimonial__slider-4">
              <div className="swiper-wrapper">
                <ImageSlider2 />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
