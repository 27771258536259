import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import Accordion from "react-bootstrap/Accordion";

export const DiplomaInSoundDesignAndMusicProduction = () => {
  return (
    <>
      <Header />

      <main>
        <section className="job__detail-top-sound pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    Diploma In Sound Design &amp; Music Production
                  </h2>
                  <p className="text-white">
                    Make Music everyday at 'state-of-the-art' Sound Studio
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title sub-section-title">
                    Diploma with Major in Sound Design &amp; Music Production
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Are you new to Sound &amp; Music Production? This
                      intensive Diploma Program is the only one of its kind to
                      provide the opportunity to record Songs, Background Score,
                      Foley at 'State-Of-The-Art' Sound Recording and Mixing
                      Studio. You can check your creation at 9.2.1 dolby Preview
                      Theatre.
                    </p>
                    <p>
                      In two semesters, you will do location sound background
                      score, foley, dubbing and mixing for minimum two short
                      film project. You will also experience the entire process
                      of filmmaking from script to screen, gaining a complete
                      and gain hands on knowledge practically in the important
                      filmmaking principles and learn the cutting edge skills
                      that equip you for an entry level job in film, TV,
                      Advertising and web industry.
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                    <h4 className="mt-3">
                      A Sound &amp; Music student will excel in his craft only
                      when he will master in all the filmmaking elements. An
                      Sound Designer or Music Director along with sound devices
                      must know the camera and Lenses, Lighting Patterns,
                      Editing, Dubbing, Vfx. Hence we provide all standard
                      filmmaking facilities to the Direction student from day
                      one.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We follow the principle of “Learning by doing” while
                            providing In-depth, hands-on, practical training
                          </li>
                          <li>
                            Training by practising film &amp; TV industry tutors
                          </li>
                          <li>
                            Easy access to Dubbing, Mixing, Foley Studios, Sync
                            Sound Setup
                          </li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>
                            Masterclass by Music Directors &amp; Sound Designers
                            from Industry
                          </li>
                          <li>
                            Participation in compulsory classroom projects in
                            every semester
                          </li>
                          <li>
                            Chance to Produce Music and Design Sound in Full
                            Length Feature Film in 5th Semester as compulsory
                            Feature Project
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Fees &amp; Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 1 Years
                            (2 Semesters)
                          </li>
                          <li>
                            <span className="fw-bold">Internship</span>:
                            Compulsory for 4 weeks
                          </li>
                          <li>
                            <span className="fw-bold">Annual Fees</span>: Rs.
                            1,50,000/- + University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <a href="/contact-us">Click here</a> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Student Feedback</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          I'm Aniket Ballal, student of MGM FILM ARTS, MGM
                          University. Here I'm studying a course called Acting
                          for Films. I would like to share my experience with
                          you.
                        </p>
                        <p>
                          “Kaal” - My first feature Short film. Basically
                          working in a professional film project with superstars
                          was a dream to me. I always wanted to be in a
                          professional project, and my dream came true only
                          because of MGM Film Arts.
                        </p>
                        <p>
                          So, When we first gathered to discuss the script we
                          learned that how a true industry project works. In
                          this discussion we learned that how should we put our
                          emotions in dialogues, how the dialogue delivery
                          should be, our Director Pratima Joshi told us each and
                          every thing very calmly.
                        </p>
                        <p>
                          Then on the first day of shooting we've started the
                          shooting with the Virtuous actress Mukta Barve. I was
                          amazed that how she was acting and behaving in a very
                          pleasent and professional manner. Her dialogues, her
                          expressions, everything was so impressive and
                          satisfying. On the next day we got an opportunity to
                          shoot the further shortfilm with Veteran Actor Mohan
                          Agashe sir. When Mohan sir plays a character, it looks
                          so fine and satisfactory. As our Nandu Bhure sir
                          (Acting Professor MGM Film Arts) who taught us that
                          how we should switch between our characters(Original
                          us and Character we are playing) very immediately as
                          an ON/OFF switch, so we practically saw Mohan sir
                          doing that flawlessly. I was acting as a grandson of
                          Mohan Agashe sir where there was a scene of crying at
                          that time I've used a method stated by Stanislavsky
                          called as Emotional Memory. It helped me alot to get
                          into my character and hold the emotions. And also it
                          was a great experience to work with Milind Jog sir,
                          the cinematographer of many hit films such as
                          Luka-chuppi, Gulabjaam, Faster Fene, etc. He taught us
                          to be creative in our works.
                        </p>
                        <p>
                          It was my fortune that i've got a chance to work with
                          Actor & Director Chandrakant Kulkarni Sir and our HOD
                          Shiv Kadam sir. I would really like to thank MGM
                          University, MGM School of Film Arts, Chancellor
                          Ankushrao Kadam sir, HOD Shiv Kadam sir, Professor
                          Nandu Bhure sir, Cinematographer Jaee Kadam mam and
                          our whole team of MGM FILM ARTS working for the short
                          film.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Faculty &amp; Mentors</Accordion.Header>
                      <Accordion.Body>
                      <ul>
                          <li>
                            <span className="fw-bold">1. Vishwajit Joshi</span>{" "}
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">
                              2. Avinash Chandrachud
                            </span>{" "}
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">3. Sahil Kulkarni</span>{" "}
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">4. Vishal Sadafule</span>{" "}
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">5. Pranav Badve</span>{" "}
                            Music Director, Sound Designer
                          </li>
                          <li>
                            <span className="fw-bold">6. Sagar Raut</span> Sound
                            Designer
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Dubbing Studio</li>
                          <li>2. Shooting Floors</li>
                          <li>3. Shooting Locations</li>
                          <li>4. Filmmaking Equipments</li>
                          <li>5. Costume Lab</li>
                          <li>6. Make-up Lab</li>
                          <li>
                            7. <a href="/facilities">Click here</a> to check other
                            facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Sagar Raut</span> +91 7972937993
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
