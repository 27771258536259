import React, { useLayoutEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
import { Reveal } from "react-reveal";
import { facultyData } from "../../../assets/json Files/facultyData";
import { Fade } from "react-reveal";
import { mentorData } from "../../../assets/json Files/mentorData";
export const Faculty = () => {
  const sectionTitle = "Faculty";


  return ( 
    <>
      <Header />
      <section className="job__detail-top pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                  {sectionTitle.split("").map((chars, index) => (
                      <Fade key={index} left duration={600} delay={index * 50}>
                        <span>{chars}</span>
                      </Fade>
                    ))}{" "}
                  </h2>
                  <p className="text-white">
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

      <section className="faculty ">
        <div className="container ">
          <div className="row">
            <div className="col-12 ">
              <h2 className="sec-title sub-section-title mb-4 animation__char_come text-center">
                
                <Reveal bottom>Mentors</Reveal>{" "}

              </h2>
            </div>
            <div className="col-12">
              <div className="row">
                {mentorData.map((mentor) => (
                  <div
                    key={mentor.id}
                    className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6 pointer-events-none"
                  >
                    <Link to="#">
                      <LazyLoadImage
                        src={mentor.image}
                        className="img-fluid "
                        alt=""
                      />
                      <div className="team__info">
                        <h4 className="team__member-name-6">{mentor.name}</h4>
                        <h5 className="team__member-role-6">{mentor.role}</h5>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
<hr />
      <section className="faculty pt-1">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2 className="sec-title sub-section-title mb-4 animation__char_come text-center ">
              <Reveal bottom>Faculty</Reveal>{" "}
              </h2>
            </div>
            {facultyData.map((faculty) => (
              <div
                key={faculty.id}
                className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-6 pointer-events-none"
              >
                <Link to="javascript:;">
                  <LazyLoadImage
                    src={faculty.image}
                    className="img-fluid "
                    alt={faculty.name}
                  />
                  <div className="team__info">
                    <h4 className="team__member-name-6">{faculty.name}</h4>
                    <h5 className="team__member-role-6">{faculty.role}</h5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
