import React from "react";
import { Header } from "../../Components/Common/Header";

export const BlogDetails = () => {
  return (
    <>
      <Header />
      <main>
        {/* Blog detail start */}
        <section className="blog__detail pt-110">
          <div className="container g-0 line pt-30">
            <span className="line-3" />
            <div className="row">
              <div className="col-xxl-8 col-xl-10 offset-xxl-2 offset-xl-1">
                <div className="blog__detail-top">
                  <h2 className="blog__detail-date animation__word_come">
                    Film Arts <span>14 July 2020</span>
                  </h2>
                  <h3 className="blog__detail-title animation__word_come">
                    Ingmar Bergman would have been 102 years old today.
                  </h3>
                  <div className="blog__detail-metalist">
                    <div className="blog__detail-meta">
                      <img
                        src={require("../../assets/imgs/blog/detail/author.png?v=0.2.1")}
                        alt="Author Picture"
                      />
                      <p>
                        Writen by <span>MGM Film Arts</span>
                      </p>
                    </div>
                    {/* <div className="blog__detail-meta">
                                            <p>Viewed <span>3 min read</span></p>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="blog__detail-thumb">
                  <img
                    src={require("../../assets/imgs/blog/detail/1.jpg?v=0.2.1")}
                    alt="Blog Thumbnail"
                    data-speed="0.5"
                  />
                </div>
              </div>
              <div className="col-xxl-8 col-xl-10 offset-xxl-2 offset-xl-1">
                <div className="blog__detail-content">
                  <p>
                    Ingmar Bergman would have turned 102 today. Bergman was born
                    on 14 July 1918 and died on 30 July 2007 in La. That's why
                    this Pandharvada is celebrated as Bergman Pandharvada in MGM
                    Film Arts Department of MGM Vidyapith. MGM Journalism
                    College's stray love for Bergman subject matter also made a
                    small garden waja theater also aamhi banalny. Tya Jagela
                    Amhi Premane Bergman's Courtyard Kehta. The representative
                    of the Swedish embassy, Bion Hömgren, would inaugurate the
                    Tevdyach Premane or the theatre.
                  </p>
                  <p>
                    But this year due to Covid 19, it is not possible to make
                    water very well. That's why we are going to see a movie
                    every day. Will discuss on that.
                  </p>
                  <img
                    src={require("../../assets/imgs/blog/detail/2.jpg?v=0.2.1")}
                    alt="Blog Image"
                  />
                  <p>
                    Shabdaat swarakriti ddlei aste, swarat chitrakriti ddlei
                    aste. But every live moment of the screen expresses many
                    invisible voices and pictures of feelings. Or Garuda's
                    greatest magician is Ingmar Bergman.
                  </p>
                  <h2>Movies In 5 Minutes</h2>
                  <p>
                    Some of the movies In 5 Minutes are presented in the video
                    of the journey of the magical world of Bergman. See for
                    sure!
                  </p>
                </div>
                <div className="blog__detail-tags">
                  <p className="sub-title-anim">
                    tags: <a href="javascript:;">Film</a>,
                    <a href="javascript:;">Film Arts</a>,
                    <a href="javascript:;">Ingmar Bergman</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Blog detail end */}
        {/* Related blog start */}
        <section className="blog__area-7 blog__animation  mt-5">
          <div className="container g-0 pb-30">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="sec-title-wrapper">
                  <h3 className="sec-title title-anim">
                    Read Our Featured Articles.
                  </h3>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <article className="blog__item">
                  <div className="blog__img-wrapper">
                    <a href="blog-details"
>
                      <div className="img-box">
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/1.jpg?v=0.1.8")}
                          alt="abcd"
                        />
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/1.jpg?v=0.1.8")}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <h4 className="blog__meta">
                    <a href="/blog-details">July 14, 2020</a>
                  </h4>
                  <h5>
                    <a href="/blog-details/" className="blog__title fw-bold">
                      इंगमार बर्गमन आज १०२ वर्षांचा झाला असता.
                    </a>
                  </h5>
                  <a href="blog-details"
 className="blog__btn">
                    Read More
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </a>
                </article>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <article className="blog__item">
                  <div className="blog__img-wrapper">
                    <a href="blog-details"
>
                      <div className="img-box">
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/2.jpg?v=0.1.8")}
                          alt=""
                        />
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/2.jpg?v=0.1.8")}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <h4 className="blog__meta">
                    <a href="blog-details"
>July 12, 2020</a>
                  </h4>
                  <h5>
                    <a href="blog-details"
 className="blog__title fw-bold">
                      स्वीडिश रंगभूमीची बीबी अँडरसन
                    </a>
                  </h5>
                  <a href="blog-details"
 className="blog__btn">
                    Read More
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </a>
                </article>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <article className="blog__item">
                  <div className="blog__img-wrapper">
                    <a href="blog-details"
>
                      <div className="img-box">
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/3.jpg?v=0.1.8")}
                          alt="Blog Thumbnail"
                        />
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/3.jpg?v=0.1.8")}
                          alt="BLog Thumbnail"
                        />
                      </div>
                    </a>
                  </div>
                  <h4 className="blog__meta">
                    <a href="blog-details"
>July 10, 2020</a>
                  </h4>
                  <h5>
                    <a href="blog-details"
 className="blog__title fw-bold">
                      रोअरिंग ट्वेन्टीज आणि शिकागो
                    </a>
                  </h5>
                  <a href="blog-details"
 className="blog__btn">
                    Read More
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </a>
                </article>
              </div>

            </div>
          </div>
        </section>
        {/* Related blog end */}
      </main>
    </>
  );
};
