import React from "react";
import { OffCanvas } from "./Homepage Components/OffCanvas";
import { HeroArea } from "./Homepage Components/HeroArea";
import { IndustryMentors } from "./Homepage Components/IndustryMentors";
import { FilmMakingPrograms } from "./Homepage Components/FilmMakingPrograms";
import { StateofArtFacilities } from "./Homepage Components/StateofArtFacilities";
import { Faculty } from "./Homepage Components/Faculty";
import { Testimonials } from "./Homepage Components/Testimonials";
import { IndustrySpeaks } from "./Homepage Components/IndustrySpeaks";
import { Contact } from "../Contact/Contact";
import { ShortfilmandBrands } from "./Homepage Components/ShortfilmandBrands";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Header } from "../../Components/Common/Header";
export const Homepage = () => {
  return (
    <>

      <OffCanvas />

      <Header />
      <main className="" >
        <HeroArea />
        {/* <LazyLoadComponent placeholder={<div>...</div>}>
            </LazyLoadComponent> */}
        {/* <LazyLoadComponent placeholder={<div>...</div>}> */}
          <FilmMakingPrograms />
        {/* </LazyLoadComponent> */}

        <LazyLoadComponent>
          <StateofArtFacilities />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <Faculty />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <IndustryMentors />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <Testimonials />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <IndustrySpeaks />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <ShortfilmandBrands />
        </LazyLoadComponent>
        <LazyLoadComponent>
          <Contact />
        </LazyLoadComponent>
      </main>

      <script src="../../assets/js/gsap.min.js?v=0.0.4"></script>
    </>
  );
};
