import React from "react";
import { Header } from "../../../../../Components/Common/Header";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
export const FilmDirectionShortPrograms = () => {
  return (
    <>
      <Header />

      <main>
        <section className="job__detail-top-film-direction pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    Direction Short Programs
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <span className="line-3"></span>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">
                    Basics of DirectionBasics of Direction
                  </h2>
                  <div className="job__detail-content">
                    <p>
                      Our 6 Months Introductory weekend Certificate is one step
                      ahead of Introductory Certificate Program. Specially
                      designed for students who want join the Film Industry at
                      Chief Assistant level. Director of Feature Film, TV
                      Serials, Web Serieses requires an reliable and skilled
                      person who can handle all the key managerial as well as
                      creative aspects.
                    </p>
                    <p>
                      In this short but intensive program students will adapt
                      every skill required to become a good chief assistant
                      director.
                    </p>
                    <p>
                      MGMU School of Film Arts is perfect echo-system for
                      budding filmmakers, actors and technicians.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-xxl-12">
                <div className="faq__list-6">
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Program Highlights</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            Students doing other regular courses or working
                            professionals can attend this program
                          </li>
                          <li>On job training</li>
                          <li>
                            Training by practicing film & TV industry tutors
                          </li>
                          <li>
                            Training with the help of Complete Professional
                            Filmmaking Setup
                          </li>
                          <li>
                            Participation in compulsory classroom projects of
                            regular students
                          </li>
                          <li>
                            Chance to work as an assistant in a Full-Length
                            Feature Film in Semester as compulsory Feature
                            Project
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Fees & Program Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">Duration</span>: 6 Months
                          </li>
                          <li>
                            <span className="fw-bold">Fees</span>: Rs. 50,000 +
                            University Fees
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Syllabus</Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <Link to="/contact-us">Click here</Link> to ask for
                          syllabus
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* <Accordion.Item eventKey="4">
                      <Accordion.Header>Student Feedback</Accordion.Header>
                      <Accordion.Body>
                        {/* Insert the provided student feedback content here */}
                    {/* </Accordion.Body>
                    </Accordion.Item> */}

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Faculty & Mentors</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <span className="fw-bold">1. N. Chande</span>
                            Director, Producer
                          </li>
                          {/* <li>
                            <span className="fw-bold">2. Ashok Rane</span>
                            Director, Producer, Film Critic
                          </li> */}
                          <li>
                            <span className="fw-bold">
                              2. Chandrakant Kulkarni
                            </span>
                            Director, Actor, Producer
                          </li>
                          <li>
                            <span className="fw-bold">3. Shiv Kadam</span>
                            Director, Writor, Academician
                          </li>
                          <li>
                            <span className="fw-bold">4. Jayprad Desai</span>
                            Producer, Director
                          </li>
                          <li>
                            <span className="fw-bold">5. Dnyanesh Zoting</span>
                            Producer, Director
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Core Facilities</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>1. Dubbing Studio</li>
                          <li>2. Shooting Floors</li>
                          <li>3. Shooting Locations</li>
                          <li>4. Filmmaking Equipments</li>
                          <li>5. Costume Lab</li>
                          <li>6. Make-up Lab</li>
                          <li>
                            7. <a href="facilities">Click here</a> to check
                            other facilities
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="mt-5">
                  <h4 className="text-white mb-2">
                    Speak with the Program Incharge
                  </h4>
                  <p>
                    <span className="fw-bold">Mr. Mahesh Harbak</span> +91
                    9834602449
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
