import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
import $ from "jquery";
import Bounce from "react-reveal/Bounce";
export const ActingForFilms = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <Header />

      <main>
        <section
          className="job__detail-top-acting pt-150 pb-100"
          style={{
            background:
              "url(assets/imgs/specilization/acting.jpg?v=0.0.4) !important;",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Acting For Films
                  </h2>
                  <p className="text-white">
                    Polish your inherent skills & start your journey as an Actor
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                                <h1>Diploma In Filmmaking</h1>
                            </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Program Intro</h2>
                  <div className="job__detail-content">
                    <p>
                      Every youthful heart desires, “To be an actor or actress”.
                      Whenever we foresee glamour and fame, we visualize
                      Bollywood in our perception. The aspirants who wish to
                      incline towards finding a peak in an acting career, have
                      one big query, “How to become an actor or actress?”
                      Generally, they have no idea due to lack of experience or
                      professional training.
                    </p>
                    <div className="pb-30"></div>
                    <h2>Program Overview</h2>
                    <p>
                      This program is the training aspirants, who wish to pursue
                      acting as a career. By opting for this course, they will
                      acquire the ability to focus on enhancing their acting
                      skills. Students will be able to act in various forms of
                      acting.
                    </p>
                    <div className="pb-30"></div>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Film City Editing Studios Sound Recording & Mixing Studio
                      Color Lab (DI) Preview Theatre (4K, 9.2.1 Dolby) Film
                      Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory VFX Lab Miniature
                      Studio
                    </p>
                    <div className="pb-30"></div>
                    <h2>Program Benefits </h2>
                    <ul>
                      <li>In-depth, hands-on, practical training</li>
                      <li>
                        Training with practicing film & TV industry tutors
                      </li>
                      <li>Audition assistance</li>
                      <li>
                        Participation in In-house and Professional Film Projects
                      </li>
                      <li>Film festival access</li>
                      <li>Easy access to Studios and shooting facilities</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5">
          <div className="container">
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-acting-for-films">
                      <h3 className="ps-title">
                        MA in ACTING <br /> FOR FILMS
                      </h3>
                      {/* <!-- <ul>
                                                <li>+ WordPress</li>
                                                <li>+ Python</li>
                                                <li>+ PHP &amp Laravel</li>
                                            </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-acting-for-films">
                      <h3 className="ps-title">
                        BA (HONS) in <br /> ACTING FOR FILMS
                      </h3>
                      {/* <!-- <ul>
                                                <li>+ WordPress</li>
                                                <li>+ Python</li>
                                                <li>+ PHP &amp Laravel</li>
                                            </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-acting-for-films">
                      <h3 className="ps-title">
                        Diploma in ACTING <br /> FOR FILMS
                      </h3>
                      {/* <!-- <ul>
                                                <li>+ WordPress</li>
                                                <li>+ Python</li>
                                                <li>+ PHP &amp Laravel</li>
                                            </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/acting-short-programs">
                      <h3 className="ps-title">
                        Short Programs <br /> (6 Months)
                      </h3>
                      {/* <!-- <ul>
                                                <li>+ WordPress</li>
                                                <li>+ Python</li>
                                                <li>+ PHP &amp Laravel</li>
                                            </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <div className="">
                  <div
                    className="btn_wrapper"
                    style={{
                      alignItems: "flex-start",
                      position: "relative !important",
                    }}
                  >
                    <Bounce top duration={1200}>
                      {" "}
                      <Link
                        to="#"
                        target="_blank"
                        className="wc-btn-primary btn-hover btn-item"
                      >
                        <span></span>
                        Download
                        <br /> brochure{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </Bounce>
                  </div>
                </div>
                </div>
             
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
