import React, { useEffect, useRef } from "react";
import heroVideo from "../../../assets/video/video.mp4";

const ShortFilmVideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
  }, []);

  return (
    <>
      <div className="video-wrapper">
        <div className="video-container" id="player"></div>
      </div>
      <video
        className="hero-vid"
        autoPlay
        playsInline
        loop
        muted
        loading="lazy"
        style={{ width: "100%" }}
        ref={videoRef}
      >
        <source src={heroVideo} type="video/mp4" />
      </video>
    </>
  );
};

export default ShortFilmVideoComponent;
