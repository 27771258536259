import React from 'react'
import { Header } from '../../Components/Common/Header'

export const MobileJournalism = () => {
  return (
    <><Header />
    
    <main>
                <section className="job__detail-top pt-150 pb-100"
                    style={{background: "url(assets/imgs/bg/mgmu.jpg?v=0.2.1) !important;"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="sec-title-wrapper">
                                    <h2 className="sec-title animation__char_come text-white mt-5">Mobile Journalism</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job__detail">
                    <div className="container g-0">

                        <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                <div className="job__detail-wrapper">
                                    <h2 className="sec-sub-title title-anim">Details of Smartphone Journalism Course</h2>
                                    <h2 className="sec-title">Most important is the Basic of Film Making and then focusing
                                        on</h2>
                                <div className="job__detail-content" style={{paddingTop: "0 !important;"}}>
                                        <ul>
                                            <li>1. Knowing what is Smartphone Journalism</li>
                                            <li>2. Exploring the potential of the smartphone.</li>
                                            <li>3. Different modes of shooting with smartphone</li>
                                            <li>4. Tools available for shooting more dynamic shots.</li>
                                            <li>5. Software for recording and editing</li>
                                            <li>6. Editing and adding sound</li>
                                            <li>7. Text and various template for making creative videos.</li>
                                            <li>8. Dubbing</li>
                                            <li>9. Chroma key editing</li>
                                            <li>10. Finally making complete short film on smartphone.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
    
    </>
  )
}
