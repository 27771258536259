import React, { useLayoutEffect, useRef } from "react";
import { Header } from "../../Components/Common/Header";

export const Events = () => {
  return (
    <>
      <Header />
      <main>
        <section className="blog__area-7 blog__animation pt-150 mt-5">
          <div className="container g-0 pb-30">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="sec-title-wrapper">
                  <h3 className="sec-title title-anim">
                    Read Our Featured Articles.
                  </h3>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <article className="blog__item">
                  <div className="blog__img-wrapper">
                    <a href="blog-details"
>
                      <div className="img-box">
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/1.jpg?v=0.1.8")}
                          alt="abcd"
                        />
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/1.jpg?v=0.1.8")}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <h4 className="blog__meta">
                    <a href="/blog-details">July 14, 2020</a>
                  </h4>
                  <h5>
                    <a href="/blog-details/" className="blog__title fw-bold">
                      इंगमार बर्गमन आज १०२ वर्षांचा झाला असता.
                    </a>
                  </h5>
                  <a href="blog-details"
 className="blog__btn">
                    Read More
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </a>
                </article>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <article className="blog__item">
                  <div className="blog__img-wrapper">
                    <a href="blog-details"
>
                      <div className="img-box">
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/2.jpg?v=0.1.8")}
                          alt=""
                        />
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/2.jpg?v=0.1.8")}
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                  <h4 className="blog__meta">
                    <a href="blog-details"
>July 12, 2020</a>
                  </h4>
                  <h5>
                    <a href="blog-details"
 className="blog__title fw-bold">
                      स्वीडिश रंगभूमीची बीबी अँडरसन
                    </a>
                  </h5>
                  <a href="blog-details"
 className="blog__btn">
                    Read More
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </a>
                </article>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <article className="blog__item">
                  <div className="blog__img-wrapper">
                    <a href="blog-details"
>
                      <div className="img-box">
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/3.jpg?v=0.1.8")}
                          alt="Blog Thumbnail"
                        />
                        <img
                          className="image-box__item"
                          src={require("../../assets/imgs/blog/1/3.jpg?v=0.1.8")}
                          alt="BLog Thumbnail"
                        />
                      </div>
                    </a>
                  </div>
                  <h4 className="blog__meta">
                    <a href="blog-details"
>July 10, 2020</a>
                  </h4>
                  <h5>
                    <a href="blog-details"
 className="blog__title fw-bold">
                      रोअरिंग ट्वेन्टीज आणि शिकागो
                    </a>
                  </h5>
                  <a href="blog-details"
 className="blog__btn">
                    Read More
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </a>
                </article>
              </div>

            </div>
          </div>
        </section>
        {/* <!-- Blog area end --> */}
      </main>
    </>
  );
};
