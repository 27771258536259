import React from 'react'
import { Header } from '../../Components/Common/Header'

export const MusicProduction = () => {
  return (
    <>
    <Header />
    <main>
        <section className="job__detail-top pt-150 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="sec-title-wrapper">
                            <h2 className="sec-title animation__char_come text-white mt-5">Music Production</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    
    </>
  )
}
