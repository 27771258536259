import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";


export const VFXAnimation = () => {
   
  return (
    <>    <Header /> 

      <main>
        <div id="smooth-wrapper" >
              <div  >
            <section
              className="job__detail-top pt-150 pb-100"
              style={{
                background: "url(assets/imgs/bg/vfx.jpg?v=0.0.4) !important;",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="sec-title-wrapper">
                      <h2 className="sec-title animation__char_come text-white mt-5">
                        VFX &amp; Animation
                      </h2>
                      <p className="text-white">
                        Create stunning special effects, Animate Your Own Movie
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="job__detail">
              <div className="container g-0 pb-30">
                <div className="row">
                  {/* <!-- <div className="text-center mt-5">
                            <h1>Diploma In Filmmaking</h1>
                        </div> --> */}
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div className="job__detail-wrapper">
                      <h2 className="sec-title">Program Intro</h2>
                      {/* <!-- <ul className="job__detail-meta">
                                    <li><span>Location</span> 2541, Austin, New York</li>
                                    <li><span>Date</span> 24, June 2022</li>
                                    <li><span>Job Type</span> Full time (Remote)</li>
                                </ul> --> */}
                      <div className="job__detail-content">
                        <p>
                          VFX or Visual Effects are a way of mixing real film
                          shooting with false or animated images. For instance,
                          a movie that shows the hero jumping off the ground and
                          flying into the air, is created using VFX. … Special
                          software for Animation & VFX has made it easy for
                          almost all filmmakers to use VFX and in their movies.
                        </p>
                        <p>
                          Animation is a method in which figures are manipulated
                          to appear as moving images. In traditional animation,
                          images are drawn and painted by hand on transparent
                          celluloid sheets to be photographed and exhibited on
                          film. Today, most animations are made with computer -
                          generated imagery (CGI).
                        </p>
                        <h2>Program Overview</h2>
                        <p>
                          The Young Aspirants who wish to pursue their passion
                          of Visual effects into a career. this fruitful course
                          is specially designed with various elective
                          specializations where students can master their
                          desired technical skills along with 30% Theory and 70%
                          Practical knowledge.
                        </p>
                        <p>
                          Students will get access to the 'State-Of-The-Art'
                          facilities as well as professional studios with world
                          class equipment and technology.
                        </p>
                        <h2>Filmmaking Facilities</h2>
                        <p>
                          Film City Editing Studios Sound Recording & Mixing
                          Studio Colour Lab (DI) Preview Theatre (4K, 9.2.1
                          Dolby) Film Cameras Cine Lights Cine Lenses Chroma
                          Floor Shooting Locations Costume Library Art Inventory
                          Vfx Lab Miniature Studio
                        </p>
                        <h2 className="mb-3">Program Benefits </h2>
                        <ul>
                          <li>On Job Training</li>
                          <li>
                            After successful completion of this course students
                            can start working as Vfx Supervisor, Animator for
                            Feature Films, Television Serials, Web Series,
                            Television Commercials, etc.
                          </li>
                          <li>
                            Participation in Aurangabad International Film
                            Festival.
                          </li>
                          <li>
                            Degree/Diploma/Certificate of Completion from MGM
                            University, Aurangabad
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="portfolio__service service-v5">
              <div className="container">
                <div className="portfolio__service-list">
                  <div className="row">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div className="portfolio__service-item">
                        <Link to="/ma-in-vfx-animation">
                          <h3 className="ps-title">
                            MA in <br /> VFX &amp; Animation
                          </h3>
                          {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div className="portfolio__service-item">
                        <Link to="/ba-hons-vfx-animation">
                          <h3 className="ps-title">
                            BA (HONS) in <br /> VFX &amp; Animation
                          </h3>
                          {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div className="portfolio__service-item">
                        <Link to="/diploma-in-vfx-and-animation">
                          <h3 className="ps-title">
                            Diploma in <br /> VFX &amp; Animation
                          </h3>
                          {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                      <div className="portfolio__service-item">
                        <Link to="/vfx-animation-short-programs">
                          <h3 className="ps-title">
                            Short Programs <br /> (3/6 Months)
                          </h3>
                          {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                        </Link>
                      </div>
                    </div>
                    {/* <!-- <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                                <div className="btn_wrapper" style={{width: "100%;"}}>
                                    <Link to="javascript:;" className="wc-btn-primary btn-hover btn-item"><span></span>
                                        Download<br/> brochure <i className="fa-solid fa-arrow-right"></i></Link>
                                </div>
                            </div> --> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};
