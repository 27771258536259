import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import img1 from "../../../assets/imgs/team/pillars/1.jpg";
import img2 from "../../../assets/imgs/team/pillars/2.jpg";
import img3 from "../../../assets/imgs/team/pillars/5.jpg";
import img4 from "../../../assets/imgs/team/pillars/3.jpg";
import img5 from "../../../assets/imgs/team/pillars/4.jpg";
import img from "../../../assets/imgs/team/pillars/pillars.jpg";
import Fade from "react-reveal/Fade";
import { Reveal } from "react-reveal";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";
export const OurPillars = () => {
  const titleText = "Our Pillars";
  const sectionTitle = "Chancellor's ";
  const sectionSubTitle = "Message";
  const sectionTitle1 = "Vice-Chancellor, ";
  const sectionSubTitle1 = "Message";
  const sectionTitle2 = "Registrar's";
  const sectionSubTitle2 = "Message,";
  const sectionTitle3 = "Dean's ";
  const sectionSubTitle3 = "Message";
  const sectionTitle4 = "HOD's ";
  const sectionSubTitle4 = "Message";
  <br />;

  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${img})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come mt-5 text-white">
                    {titleText.split("").map((char, index) => (
                      <Fade key={index} left duration={600} delay={index * 50}>
                        <span>{char}</span>
                      </Fade>
                    ))}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="chairman__area pt-5">
          <div className="container">
            {/* <!-- CHANCELLOR --> */}
            <div className="row align-items-center mb-5">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center order-lg-2">
                <div className="chairm__img">
                  <LazyLoadImage
                    src={img1}
                    alt=""
                    className="img-fluid"
                    effect="blur"
                  />
                </div>
                <div className="chairm__block">
                  <div className="chairm__name">
                    <h3 className="sec-title title-anim">
                      Shri. Ankushrao Kadam
                    </h3>
                  </div>
                  <div className="chairm__details sec-sub-title sub-title-anim-top">
                    <h6>
                      Chancellor, <br />
                      MGM University
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 order-lg-1">
                <div className="chairm__mes">
                  <div className="section__title-wrapper">
                    <h2 className="sec-title title-anim">
                      {sectionTitle.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}{" "}
                      <br />
                      {sectionSubTitle.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}
                    </h2>
                  </div>
                  <h4 className="sec-sub-title our-pillar-sec-sub-title title-anim">
                    <Reveal bottom> Dear Students,</Reveal>
                  </h4>
                  <p>
                    Cinema is the only art that has established its existence in
                    every part of the world. With a glorious history of more
                    than 100 years, Film Industry is not only the biggest
                    revenue-generating industry but it also provides great
                    employment.
                  </p>
                  <p>
                    In India, the history of cinema is glorious. To continue the
                    success story there is a need for formal training in several
                    dimensions of filmmaking. Such training must be supported by
                    continuous upgrading technology and with guidance by
                    veterans of the industry. I am sure that with the help of
                    our unique skill development tasks program we will be able
                    to produce multi-talented professionals to contribute to the
                    commercial as well as meaningful cinema.
                  </p>
                  <p>With warm regards,</p>
                  <p>
                    I welcome all budding film artists and technicians to MGM!
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- CHANCELLOR --> */}

            {/* <!-- VICE CHANCELLOR --> */}
            <div className="row align-items-center mt-5 mb-5">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center order-lg-1">
                <div className="chairm__img">
                  <LazyLoadImage
                    src={img2}
                    alt=""
                    className="img-fluid"
                    effect="blur"
                  />
                </div>
                <div className="chairm__block">
                  <div className="chairm__name">
                    <h3 className="sec-title title-anim">Dr. Vilas Sapkal</h3>
                  </div>
                  <div className="chairm__details sec-sub-title sub-title-anim-top">
                    <h6>
                      Vice-Chancellor, <br />
                      MGM University, Aurangabad
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 order-lg-2">
                <div className="chairm__mes">
                  <div className="section__title-wrapper">
                    <h2 className="sec-title title-anim">
                      {sectionTitle1.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}{" "}
                      <br />
                      {sectionSubTitle1.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}
                    </h2>
                  </div>
                  <h4 className="sec-sub-title our-pillar-sec-sub-title title-anim">
                    <Reveal bottom>Dear Students,</Reveal>
                  </h4>
                  <p>
                    MGM University, Aurangabad has been established by a
                    Government of Maharashtra Act for the development and
                    advancement of higher education in the state. The University
                    has Gandhi's philosophy and values as its focal themes. The
                    University with a distinct track record of excellence in
                    education, research, industry connections, and services is
                    now embarking on creating multidisciplinary education with
                    the mission of developing 21st-century capabilities of
                    learners and providing the highest quality educational
                    opportunities for lifelong learning. The University is
                    offering several innovative programs which are in line with
                    changing landscapes of knowledge and placement worldwide to
                    achieve the highest potential of the individual. This makes
                    the learners choose their learning trajectories and programs
                    according to their talents and interests. Students will have
                    opportunities to learn recent and advanced subjects in
                    emerging fields with unique experiential learning through a
                    flexible curriculum and engaging pedagogy.
                  </p>
                  <p>
                    With the state-of-the-art infrastructure and competent and
                    highly energized faculty under the Mentorship of visionary
                    governance of the University, the outcome of education and
                    research will be of standards that are comparable with
                    global standards. With a focused vision on innovation and
                    excellence and a planned mission of all-around and holistic
                    human development, the University ensures the transformation
                    of students to meet their aspirations and goals.
                  </p>
                  <p>
                    I welcome talented and creative minds to the University to
                    realize their highest potential through quality education
                    and research.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- VICE CHANCELLOR --> */}

            {/* <!-- REGISTRAR --> */}
            <div className="row align-items-center mt-5 mb-5">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center order-lg-2">
                <div className="chairm__img">
                  <LazyLoadImage
                    src={img3}
                    alt=""
                    className="img-fluid"
                    effect="blur"
                  />
                </div>
                <div className="chairm__block">
                  <div className="chairm__name">
                    <h3 className="sec-title title-anim">Dr. Ashish Gadekar</h3>
                  </div>
                  <div className="chairm__details sec-sub-title sub-title-anim-top">
                    <h6>
                      Registrar, <br />
                      MGM University
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 order-lg-1">
                <div className="chairm__mes">
                  <div className="section__title-wrapper">
                    <h2 className="sec-title title-anim">
                      {sectionTitle2.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}{" "}
                      <br />
                      {sectionSubTitle2.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}
                    </h2>
                  </div>
                  <h4 className="sec-sub-title our-pillar-sec-sub-title title-anim">
                    <Reveal bottom>Dear Students,</Reveal>
                  </h4>
                  <p>
                    Cinema is the only art that has established its existence in
                    every part of the world. With a glorious history of more
                    than 100 years, Film Industry is not only the biggest
                    revenue-generating industry but it also provides great
                    employment.
                  </p>
                  <p>
                    In India, the history of cinema is glorious. To continue the
                    success story there is a need for formal training in several
                    dimensions of filmmaking. Such training must be supported by
                    continuous upgrading technology and with guidance by
                    veterans of the industry. I am sure that with the help of
                    our unique skill development tasks program we will be able
                    to produce multi-talented professionals to contribute to the
                    commercial as well as meaningful cinema.
                  </p>
                  {/* <!-- <p>With warm regards,</p> --> */}
                  {/* <!-- <p>
                    I welcome all budding film artists and technicians to MGM!
                  </p> --> */}
                </div>
              </div>
            </div>
            {/* <!-- REGISTRAR --> */}

            {/* <!-- DEAN --> */}
            <div className="row align-items-center mt-5 mb-5">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center order-lg-1">
                <div className="chairm__img">
                  <LazyLoadImage
                    src={img4}
                    alt=""
                    className="img-fluid"
                    effect="blur"
                  />
                </div>
                <div className="chairm__block">
                  <div className="chairm__name">
                    <h3 className="sec-title title-anim">
                      Prof. (Dr.) Rekha Shelke
                    </h3>
                  </div>
                  <div className="chairm__details sec-sub-title sub-title-anim-top">
                    <h6>
                      Dean, <br />
                      Faculty of Social Sciences & Humanities - MGM University
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 order-lg-2">
                <div className="chairm__mes">
                  <div className="section__title-wrapper">
                    <h2 className="sec-title title-anim">
                      {sectionTitle3.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}{" "}
                      <br />
                      {sectionSubTitle3.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}
                    </h2>
                  </div>
                  <h4 className="sec-sub-title our-pillar-sec-sub-title title-anim">
                    <Reveal bottom>Dear young friends,</Reveal>
                  </h4>
                  <p>“A learning curve is essential to growth”</p>
                  <p>
                    The Faculty of Social Sciences & Humanities, MGM University
                    aims at achieving its goal of helping learners to gain both,
                    knowledge along with applied skills, by nurturing personal
                    as well as professional growth and to acquire progressive
                    career advancement as a determined professional and leader
                    in career and life.
                  </p>
                  <p>
                    We believe in multi-faceted development of the students to
                    withstand the challenges of global competitiveness. Today,
                    education is transforming towards the outcome-based
                    education and credit based systems. Following the vision
                    set, experienced faculties, skilled supporting staff and
                    state-ofthe-art infrastructure and studios are provided to
                    groom the student as a complete professional ready for the
                    industry and entrepreneurship. You will have a great time in
                    the MGM family!
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- DEAN --> */}

            {/* <!-- HOD --> */}
            <div className="row align-items-center mt-5 mb-5">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center order-lg-2">
                <div className="chairm__img">
                  <LazyLoadImage
                    src={img5}
                    alt=""
                    className="img-fluid"
                    effect="blur"
                  />
                </div>
                <div className="chairm__block">
                  <div className="chairm__name">
                    <h3 className="sec-title title-anim">Prof. Shiv Kadam</h3>
                  </div>
                  <div className="chairm__details sec-sub-title sub-title-anim-top">
                    <h6>
                      Head, <br />
                      MGM School of Film Arts
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 order-lg-1">
                <div className="chairm__mes">
                  <div className="section__title-wrapper">
                    <h2 className="sec-title title-anim">
                      {sectionTitle4.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}{" "}
                      <br />
                      {sectionSubTitle4.split("").map((chars, index) => (
                        <Fade
                          key={index}
                          left
                          duration={600}
                          delay={index * 50}
                        >
                          <span>{chars}</span>
                        </Fade>
                      ))}
                    </h2>
                  </div>
                  <h4 className="sec-sub-title our-pillar-sec-sub-title title-anim">
                    <Reveal bottom>Dear Students,</Reveal>
                  </h4>
                  <p>
                    We at MGM University believe in providing world-class
                    technology and facilities along with the guidance from
                    proficient personalities from the film industry and shape
                    the proper growth of a Film Arts learner. Hence we have
                    established Film studios (Production and Post Production)
                    with state-of the-art filmmaking facilities for the
                    continuous skill development of our students. With guidance
                    and involvement by great industry professionals we have
                    prepared a unique training module, which will not only make
                    our students conceptually strong but they will also evolve
                    as masters in the craft of filmmaking.I would like to assure
                    you that, MGM School of Film Arts ideology and practice will
                    work as never ending fuel to your creativity. Welcome Folks!
                  </p>
                  <p className="pt-2">
                    (Shiv has Written, Directed and Edited several Marathi
                    Feature Films like Aag, Cappuccino, Jawani Zindabad, Wings
                    of Fire. Shiv is contributing as Creative Director for
                    Aurangabad International Film Festival, he was also a Jury
                    at 11th Bangaluru International Film Festival. He has done
                    his Post Graduation in Theatre and he is a Gold Medalist
                    from Dr. BAM University. He is having an experience of more
                    than 20 years in the film industry.)
                  </p>
                  <div className="team__member-social">
                    <h4 className="work-title pb-2">Follow :</h4>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/shivkadam1">
                          <span>
                            <i className="fa-brands fa-facebook-f"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/shivdershan">
                          <span>
                            <i className="fa-brands fa-twitter"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>
                            <i className="fa-brands fa-instagram"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/in/shivdarshan-kadam-2a907437/?originalSubdomain=in">
                          <span>
                            <i className="fa-brands fa-linkedin"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UCtCF2lvhGntrJrNrQfZNFhA?view_as=subscriber">
                          <span>
                            <i className="fa-brands fa-youtube"></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span>
                            <i className="fa-brands fa-google"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- HOD --> */}
          </div>
        </section>
      </main>
    </>
  );
};
