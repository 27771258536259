export const mentorData = [
    {
      id: 1,
      name: "Bishwadeep Chatterjee",
      role: "Bishwadeep Chatterjee is an Indian film sound designer, sound editor and audio mixer.",
      image: require("../imgs/team/mentors/1.jpg"),
    },
    {
      id: 2,
      name: "Dharam Gulati",
      role: "Dharam Gulati is known for Muigwithania (2010), Project 103 and Hum Farishte Nahin (1988).",
      image: require("../imgs/team/mentors/2.jpg"),
    },
    {
      id: 3,
      name: "Mahesh Aney",
      role: "Is a Film & Television Institute of India (FTII) graduate",
      image: require("../imgs/team/mentors/3.jpg"),
    },
    {
      id: 4,
      name: "Chandrakant Kulkarni",
      role: "Is a Marathi theatre & film director, script-writer, & actor",
      image: require("../imgs/team/mentors/4.jpg"),
    },
    // {
    //   id: 5,
    //   name: "Ashok Rane",
    //   role: "Renowned Writer 7 Film Critic. Bagged two National Awards for Documentary & Best Book on Films",
    //   image: require("../imgs/team/mentors/5.jpg"),
    // },
    {
      id: 6,
      name: "Makarand Anaspure",
      role: "Anaspure directed the Marathi film Dambis, in 2011",
      image: require("../imgs/team/mentors/6.jpg"),
    },
    {
      id: 7,
      name: "Yateen Karyekar",
      role: "Is a veteran Indian actor in the Bollywood film industry",
      image: require("../imgs/team/mentors/7.jpg"),
    },
    {
      id: 8,
      name: "Mukta Barve",
      role: "Is a Indian film, television, & theatre actor & producer",
      image: require("../imgs/team/mentors/8.jpg"),
    },
    // Add more mentor members here...
  ];