import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Bounce from "react-reveal/Bounce";
export const ButtonFooter = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="/contact-us"
            className="wc-btn-primary btn-hover btn-item"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span></span> Apply Now
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonAudition = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="contact-us"
            className="wc-btn-primary btn-hover btn-item"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span></span> Send Message
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonDiplomaBrochure = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-primary btn-hover btn-item" style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
          >
            <span></span> Download
            <br />
            brochure <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonDiplomaSyllabus = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-black btn-hover btn-item"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span></span> Download <br /> Syllabus
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonBAHonsBrochure = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-primary btn-hover btn-item" style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
          >
            <span></span> Download
            <br />
            brochure <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonBAHonsSyllabus = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-black btn-hover btn-item"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span></span> Download <br /> Syllabus
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonMAinFMBrochure = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-primary btn-hover btn-item" style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
          >
            <span></span> Download
            <br />
            brochure <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonMAinFMSyllabus = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-black btn-hover btn-item"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <span></span> Download <br /> Syllabus
            <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};
export const ButtonShortProgramsBrochure = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
 
    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <div className="btn_wrapper" style={{ position: "relative !important" }}>
        <Bounce top duration={1200}>
          <Link
            to="#"
            target="_blank"
            className="wc-btn-primary btn-hover btn-item" style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
          >
            <span></span> Download
            <br />
            brochure <i className="fa-solid fa-arrow-right"></i>
          </Link>
        </Bounce>
      </div>
    </>
  );
};