import React from "react";
import VideoComponent from "../Homepage Components/VideoComponent";
import styled, { keyframes } from "styled-components";

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const Marquee = styled.div`
  display: flex;
  overflow: hidden;
  user-select: none;
`;

const MarqueeGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  width: 3000px;
  animation: ${scrollX} 45s linear infinite;
`;

export const HeroArea = () => {
  return (
    <section className="service__hero-2">
      <div className="announcements">
        <Marquee>
          <MarqueeGroup id="margin-top">
            <p>Learning By Doing</p>
            <p>|</p>
            <p>
              Complete Feature Film Production Facility Provided Free To The
              Students
            </p>
            <p>|</p>
            <p>
              Editing, Dubbing, Mixing, VFX, DI Studio Facility Provided Free To
              The Students
            </p>
            <p>|</p>
            <p>
              Feature Film Production Every Year Compulsory For UG, PG Students
            </p>
            <p>|</p>
            <p>Admission open for 2024</p>
            <p>|</p>
          </MarqueeGroup>
          <MarqueeGroup id="margin-top">
            <p>Learning By Doing</p>
            <p>|</p>
            <p>
              Complete Feature Film Production Facility Provided Free To The
              Students
            </p>
            <p>|</p>
            <p>
              Editing, Dubbing, Mixing, VFX, DI Studio Facility Provided Free To
              The Students
            </p>
            <p>|</p>
            <p>
              Feature Film Production Every Year Compulsory For UG, PG Students
            </p>
            <p>|</p>
            <p>Admission open for 2024</p>
            <p>|</p>
          </MarqueeGroup>
        </Marquee>
      </div>
      <VideoComponent />
    </section>
  );
};
