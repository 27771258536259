import React from "react";
import ImageSlider from "../../../Components/Common/ImageSlider";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Reveal } from "react-reveal";
export const IndustryMentors = () => {
  return (
    <>
      <section className="team__area-6 pt-100 pb-120 ">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="" style={{ textAlign: "center" }}>
              <div
                className="sec-title-wrapper"
                style={{ textAlign: "center" }}
              >
                <h2
                  className="sec-title mt-0 animation__char_come"
                  style={{ textAlign: "center" }}
                >
                  <Reveal bottom> Industry Mentors</Reveal>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: "15px", height: "400px" }}>
          <LazyLoadComponent placeholder={<div>...</div>}>
            <ImageSlider />
          </LazyLoadComponent>
        </div>
      </section>
    </>
  );
};
