import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Bounce } from "react-reveal";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Header } from "../../../Components/Common/Header";

export const SoundDesignMusicProduction = () => {
  useEffect(() => {
    $(".btn-hover").on("mouseenter", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });

    $(".btn-hover").on("mouseout", function (e) {
      var x = e.pageX - $(this).offset().left;
      var y = e.pageY - $(this).offset().top;

      $(this).find("span").css({
        top: y,
        left: x,
      });
    });
  }, []);
  return (
    <>
      <Header />

      <main>
        <section className="job__detail-top-sound pt-150 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white mt-5">
                    Sound Design &amp; Music Production
                  </h2>
                  <p className="text-white">
                    Turn your passion for music into your profession
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0 pb-30">
            <div className="row">
              {/* <!-- <div className="text-center mt-5">
                            <h1>Diploma In Filmmaking</h1>
                        </div> --> */}
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <h2 className="sec-title">Program Intro</h2>
                  {/* <!-- <ul className="job__detail-meta">
                                    <li><span>Location</span> 2541, Austin, New York</li>
                                    <li><span>Date</span> 24, June 2022</li>
                                    <li><span>Job Type</span> Full time (Remote)</li>
                                </ul> --> */}
                  <div className="job__detail-content">
                    <p>
                      The media today in India is demanding technically sound
                      and stable creative Audio Engineers capable enough to
                      handle enormous power of today's technical development in
                      Audio Engineering. May it be in the field of E-learning,
                      Radio/TV broadcasting, pod casting, Gaming, Mobile
                      learning, Animation, Cinema, Music Industry or simply on
                      the internet sound plays a key role and gives rise to
                      various fields of work.
                    </p>
                    <h2>Program Overview</h2>
                    <p>
                      This program is for the people who want to start their
                      career in the field of sound engineering and music
                      production, that's why MGM School of Film Arts provides
                      you the program from basic level to advanced level.This
                      program will provide you with the knowledge and tools
                      necessary to record and produce professional-quality
                      music. As an artist and producer, you'll start by defining
                      your identity, vision, and goal. You'll then learn about
                      the technical aspects of music production and sound
                      design, such as how sound is converted into audio signals,
                      recording techniques. You will also learn how to use the
                      industry standard Digital Audio Workstation, Pro Tools,
                      logic pro x to create professional recordings.
                    </p>
                    <h2>Filmmaking Facilities</h2>
                    <p>
                      Sound Recording & Mixing Studio Foley Setup Sync Sound
                      Setup Colour Lab (DI) Preview Theatre (4K, 9.2.1 Dolby)
                      Film Cameras Cine Lights Cine Lenses Chroma Floor Shooting
                      Locations Costume Library Art Inventory Vfx Lab Miniature
                      Studio Film City Editing Studios{" "}
                    </p>
                    <h2 className="mb-3">Program Benefits </h2>
                    <ul>
                      <li>
                        Student will be able to create his own Music Album
                      </li>
                      <li>
                        Opportunity to work with the industry professionals
                      </li>
                      <li>
                        Degree/Diploma/Certificate after successfully completion
                        of the course from MGM University
                      </li>
                      <li>
                        Participation in Aurangabad International Film Festival.
                      </li>
                      <li>On job training</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="portfolio__service service-v5">
          <div className="container">
            <div className="portfolio__service-list">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ma-in-sound-design-music-production-022">
                      <h3 className="ps-title">
                        MA in Sound Design <br /> &amp; Music Production
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/ba-hons-sound-design-music-production">
                      <h3 className="ps-title">
                        BA (HONS) in Sound Design <br /> &amp; Music Production
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/diploma-in-sound-design-and-music-production/  ">
                      <h3 className="ps-title">
                        Diploma in Sound Design <br /> &amp; Music Production
                      </h3>
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <div className="portfolio__service-item">
                    <Link to="/sound-design-short-programs">
                      <h3 className="ps-title">
                        Short Programs <br /> (6 Months)
                      </h3>
                      {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                  <div
                    className="btn_wrapper"
                    style={{ position: "relative", alignItems: "flex-start" }}
                  >
                    <Bounce top duration={1200}>
                      <Link
                        to="#"
                        target="_blank"
                        className="wc-btn-primary btn-hover btn-item"
                      >
                        <span></span>
                        Download
                        <br /> brochure{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </Bounce>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
