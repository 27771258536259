import React from 'react'
import { Header } from '../../Components/Common/Header'

export const ShortFilmProduction = () => {
  return (
    <>
    <Header/>
    <main>
        <section
            className="job__detail-top pt-150 pb-100"
            style={{
            background: `url(${require("../../assets/imgs/bg/film-production.jpg?v=0.2.1")})`
            }}
        >
            <div className="container">
            <div className="row">
                <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                    <h2 className="sec-title animation__char_come text-white mt-5">
                    Short Film Production
                    </h2>
                </div>
                </div>
            </div>
            </div>
        </section>
        <div className="hero__area-10">
            <div className="swiper hero10_activition">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src={require("../../assets/imgs/hero/portfolio-2/1.jpg?v=0.2.1")}
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src={require("../../assets/imgs/hero/portfolio-2/2.jpg?v=0.2.1")}
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src="assets/imgs/hero/portfolio-2/3.jpg?v=0.2.1"
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src="assets/imgs/hero/portfolio-2/4.jpg?v=0.2.1"
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src="assets/imgs/hero/portfolio-2/5.jpg?v=0.2.1"
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src="assets/imgs/hero/portfolio-2/6.jpg?v=0.2.1"
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src="assets/imgs/hero/portfolio-2/7.jpg?v=0.2.1"
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
                <div className="swiper-slide">
                <a href="javascript:;">
                    <div className="hero10">
                    <div className="hero10__thumb">
                        <img
                        src="assets/imgs/hero/portfolio-2/8.jpg?v=0.2.1"
                        alt="Image"
                        />
                    </div>
                    </div>
                </a>
                </div>
            </div>
            <div className="swiper-button-next hero_next">
                Next
                <img
                src="assets/imgs/hero/portfolio-2/slider-arrow-right.svg?v=0.2.1"
                alt=""
                />
            </div>
            <div className="swiper-button-prev hero_prev">
                <img
                src="assets/imgs/hero/portfolio-2/slider-arrow-left.svg?v=0.2.1"
                alt=""
                />
                Prev
            </div>
            <div className="swiper-pagination" />
            </div>
        </div>
        <section className="portfolio__area-3">
            <div className="container">
            <div className="row">
                <div className="col-xxl-12">
                <div className="swiper portfolio__slider-3">
                    <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="portfolio__slide-3">
                        <a href="javascript:;">
                            <img
                            src={require("../../assets/imgs/portfolio/3/1.jpg?v=0.2.1")}
                            alt="Portfolio Image"
                            />
                        </a>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="portfolio__slide-3">
                        <a href="javascript:;">
                            <img
                            src={require("../../assets/imgs/portfolio/3/2.jpg?v=0.2.1")}
                            alt="Portfolio Image"
                            />
                        </a>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="portfolio__slide-3">
                        <a href="javascript:;">
                            <img
                            src="assets/imgs/portfolio/3/3.jpg?v=0.2.1"
                            alt="Portfolio Image"
                            />
                        </a>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="portfolio__slide-3">
                        <a href="javascript:;">
                            <img
                            src="assets/imgs/portfolio/3/4.jpg?v=0.2.1"
                            alt="Portfolio Image"
                            />
                        </a>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="portfolio__slide-3">
                        <a href="javascript:;">
                            <img
                            src="assets/imgs/portfolio/3/5.jpg?v=0.2.1"
                            alt="Portfolio Image"
                            />
                        </a>
                        </div>
                    </div>
                    </div>
                    {/* <div className="swiper-pagination"></div> */}
                    <div className="swiper-btn">
                    <div className="pp-prev">
                        <i className="fa-solid fa-arrow-left" />
                    </div>
                    <div className="pp-next">
                        <i className="fa-solid fa-arrow-right" />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
    </main>
        
    
    </>
  )
}
