import React, { useEffect } from "react";
import LogoWhiteSVG from "../../assets/imgs/logo/site-logo-white-2.svg";
import $ from "jquery";
import "../../assets/css/headerFooter.css";
import { Link } from "react-router-dom";
import { ButtonFooter } from "./Button/Button";
export const Footer = () => {
  // useEffect(() => {
  //   $(".btn-hover").on("mouseenter", function (e) {
  //     var x = e.pageX - $(this).offset().left;
  //     var y = e.pageY - $(this).offset().top;

  //     $(this).find("span").css({
  //       top: y,
  //       left: x,
  //     });
  //   });

  //   $(".btn-hover").on("mouseout", function (e) {
  //     var x = e.pageX - $(this).offset().left;
  //     var y = e.pageY - $(this).offset().top;

  //     $(this).find("span").css({
  //       top: y,
  //       left: x,
  //     });
  //   });
  // }, []);
  return (
    <>
      {/* <!-- Footer area start --> */}
      <footer className="footer__area" >
        <div className="footer__btm " >
          <div className="">
            <div className="row footer__row">
              <div className="col-xxl-12 ">
                <div className="footer__inner">
                  <div className="footer__widget">
                    <img
                      style={{ width: "150px", height: "85px" }}
                      src={LogoWhiteSVG}
                      alt=""
                    />
                    <p>
                      Empowering aspiring filmmakers to bring their stories to
                      life through quality education, resources, and industry
                      connections.
                    </p>
                    <ul className="footer__social">
                      <li>
                        <Link to="https://www.facebook.com/mgmfilmarts">
                          <span>
                            <i className="fa-brands fa-facebook-f"></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://twitter.com/mgmfilmarts">
                          <span>
                            <i className="fa-brands fa-twitter"></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.youtube.com/c/MGMFilmArts">
                          <span>
                            <i className="fa-brands fa-youtube"></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.linkedin.com/company/mgm-film-arts">
                          <span>
                            <i className="fa-brands fa-linkedin"></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://www.instagram.com/mgmfilmarts">
                          <span>
                            <i className="fa-brands fa-instagram"></i>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer__widget-2">
                    <h2 className="footer__widget-title">Programs</h2>
                    <ul className="footer__link">
                      <li>
                        <Link to="ma-in-filmmaking">
                          Post Graduate Programs
                        </Link>
                      </li>
                      <li>
                        <Link to="graduation-program">Graduation Program</Link>
                      </li>
                      <li>
                        <Link to="certificate-program-2">Diploma Program</Link>
                      </li>
                      <li>
                        <Link to="certificate-programs-in-film-making">
                          Certificate Program in Filmmaking
                        </Link>
                      </li>
                      <li>
                        <Link to="/ftii-courses">
                          Film Appreciation Course CHH.Sambhajinagar
                          (Aurangabad)
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer__widget-3">
                    <h2 className="footer__widget-title">Important Links</h2>
                    <ul className="footer__link">
                      <li>
                        <Link to="facilities">Facilities</Link>
                      </li>
                      <li>
                        <Link to="/gallery">
                          Campus Life
                        </Link>
                      </li>
                      <li>
                        <Link to="/ftii-courses">
                          FTII Courses, CHH.Sambhajinagar (Aurangabad)
                        </Link>
                      </li>
                      <li>
                        <Link to="admission-procedure">
                          ADMISSION PROCEDURE
                        </Link>
                      </li>
                      <li>
                        <Link to="contact-us">CONTACT US</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer__widget-4">
                    <h2 className="footer__widget-title">Contact Us</h2>
                    <ul className="footer__contact">
                      <li>
                        <span>
                          MGM University, N-6, CIDCO, <br /> CHH.Sambhajinagar
                          (Aurangabad), Maharashtra, India
                        </span>
                      </li>
                      <li>
                        <Link to="tel:02406481052">0240-6481052</Link>
                      </li>
                      <li>
                        <Link to="mailto:hodfilmarts@mgmu.ac.in">
                          hodfilmarts@mgmu.ac.in
                        </Link>
                      </li>
                    </ul>
                    <ButtonFooter />
                    <h3 className="contact-time">09 : 00 AM - 10 : 30 PM</h3>
                    <h4 className="contact-day">Saturday - Thursday</h4>
                  </div>

                  <div className="footer__copyright">
                    <p>
                      &copy; 2024 | All rights reserved by{" "}
                      <Link to="#" target="_blank">
                        MGM School Of Film Arts
                      </Link>
                    </p>
                  </div>

                  <div className="footer__subscribe pointer-events-none">
                    <form  className="">
                      <input
                        type="email"
                        name="email"
                        placeholder="Subscribe To Our Newsletter"
                      />
                      <button type="submit" className="subs-btn">
                        <i className="fa-solid fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- Footer area end --> */}
    </>
  );
};
