import React, { useEffect, useState } from "react";
import { Header } from "../../Components/Common/Header";

export const WalkthroughLeads = () => {
  const [workshopData, setWorkshopData] = useState([]);
  const [showData, setShowData] = useState(false);
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const correctPassword = process.env.REACT_APP_PASSWORD1;
  const apiUrl = process.env.REACT_APP_API_URL1;
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setShowData(true);
      setIsModalOpen(false);
      fetchData();
      console.log(password, "ppp", correctPassword, "mmm");
    } else {
      console.log(password, correctPassword);
      alert("Incorrect password. Access denied.");
    }
  };

  const fetchData = () => {
    fetch(apiUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error fetching data");
        }
      })
      .then((data) => {
        const jsonData = data;
        setWorkshopData(jsonData.Data.Records);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 404) {
          alert("The requested resource could not be found.");
        } else {
          alert("An error occurred while fetching the data.");
        }
      });
  };

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const exportTableToExcel = (tableID, filename = "") => {
    const downloadLink = document.createElement("a");
    const dataType = "application/vnd.ms-excel";
    const table = document.getElementById(tableID);
    const tableHTML = table.outerHTML.replace(/ /g, "%20");

    downloadLink.href = "data:" + dataType + "," + tableHTML;
    downloadLink.download = filename + ".xls";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Header />
      <main>
        {showData ? (
          <section className="table  mt-5" style={{paddingTop:"100px"}}>
            <div className="container">
              <h2   className="sec-title text-center mb-0">
                Walk Through Cinema Leads
              </h2>
              <div style={{paddingTop:"20px"}} id="tableContainer" className="table table-responsive">
                <table className="table table-dark table-hover" id="myTable">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th>FirstName</th>
                      <th>LastName</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>ProgramName</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workshopData.map((item, index) => (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <td>{item.FirstName}</td>
                        <td>{item.LastName}</td>
                        <td>{item.Email}</td>
                        <td>{item.Phone}</td>
                        <td>{item.Gender}</td>
                        <td>{item.ProgramName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <a
                className="scroll-top"
                onClick={() => exportTableToExcel("myTable", "Workshop Leads")}
              >
                Download Excel
              </a>
            </div>
          </section>
        ) : null}

        {isModalOpen && (
          <div className="modal" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={handlePasswordSubmit}>
                  <div className="modal-body" style={{ background: "none" }}>
                    <div className="form-group">
                      <label htmlFor="password">Password:</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mt-3 right-0"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {isModalOpen && <div className="modal-backdrop show"></div>}
      </main>
    </>
  );
};
