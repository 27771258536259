import React, { useLayoutEffect, useRef } from "react";
import { Header } from "../../Components/Common/Header";

export const Methodology = () => {
  return (
    <>
      <Header />
      <main>
        <section
          className="job__detail-top pt-150 pb-100"
          style={{
            background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.0.4")}`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sec-title-wrapper">
                  <h2 className="sec-title animation__char_come text-white">
                    METHODOLOGY
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job__detail">
          <div className="container g-0">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="job__detail-wrapper">
                  <div
                    className="job__detail-content"
                    style={{ paddingTop: "0px" }}
                  >
                    <h2 style={{ paddingTop: "20px" }}>
                      “True art is characterized by an irresistible urge in the
                      creative artist.” - Albert Einstein
                    </h2>
                    <p>
                      There are two major streams in the academics of cinema,
                      film studies and film making. Film studies unfortunately
                      focuses on the philosophical or discussion oriented part
                      of studying cinema, where film making focuses on the
                      technical aspect of film making. We ate MGM Film Arts
                      firmly believe that film is a art and every student of MGM
                      Film Arts need to be groomed as artist first of all.
                      Alfred Hitchcock once said that if he will start a film
                      school he will first make each student to understand and
                      learn art. To follow the ideology every course will start
                      with allied arts workshops where every student will get
                      trained about elements & principles, fundamentals of every
                      art like painting, sculpture, music, dance, etc
                    </p>
                    <h2 style={{ paddingTop: "20px" }}>
                      Knowledge is of no value unless you put it into practice.
                      -Anton Chekhov
                    </h2>
                    <p>
                      Knowledge Workshops: Knowledge workshops will be usually
                      conducted in the classrooms or preview theatres according
                      to the context, where experts will explain the theoretical
                      part. It will be conducted regularly as per the syllabus
                      every day for 1hr 30 min to 3 hrs. Practice sessions:
                      Practice sessions are the sessions based on practical. Our
                      experts defined certain set of skills need to be developed
                      in students. E.g. a cinematographer must excel in focus
                      pulling, hence he is supposing to de intensive practice
                      under the guidance of mentor to excel in the skill. We at
                      MGM Film Arts don’t believe in competition or
                      examinations, here students will get redo until they do
                      the satisfactory work.
                    </p>
                    <h2 style={{ paddingTop: "20px" }}>
                      Creativity is allowing yourself to make mistakes. Art is
                      knowing which ones to keep. -Scott Adams
                    </h2>
                    <p>
                      Developing Judgement, Sensitivity and Creativity as
                      explained earlier filmmaking is not just about talking
                      some philosophy or learning any equipment. A student who
                      knows any editing software cannot be certified as an
                      editor. He must achieve the excellence in the art of film
                      editing. To achieve excellence we will make them work on
                      the result of their judgments. Specially defined programs
                      will boost the sensitivity and creativity in the students.
                      Rumination: One cannot achieve excellence in a day. As
                      Aristotle says, “Excellence is an art won by training and
                      habituation. We do not act rightly because we have virtue
                      or excellence, but we rather have those because we have
                      acted rightly. We are what we repeatedly do. Excellence,
                      then, is not an act but a habit.”
                    </p>
                    <h2 style={{ paddingTop: "20px" }}>
                      We have created a perfect environment for the rumination
                      on films and the art of film at MGM Film Arts.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
