import React from "react";
import { Link } from "react-router-dom";
export const OffCanvas = () => {
  return (
    <>
      {" "}
      {/* <!-- Offcanvas area start --> */}
      <div className="offcanvas__area">
        <div className="offcanvas__body">
          <div className="offcanvas__left">
            <div className="offcanvas__logo">
              <Link to="javascript:;">
                <img
                  src="assets/imgs/logo/site-logo-white-2.svg"
                  alt="Offcanvas Logo"
                />
              </Link>
            </div>
            <div className="offcanvas__social">
              <h3 className="social-title">Follow Us</h3>
              <ul>
                <li>
                  <Link to="https://www.instagram.com/mgmfilmarts">Instagram</Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/mgmfilmarts">Facebook</Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/mgm-film-arts">
                    Linkedin
                  </Link>
                </li>
                <li>
                  <Link to="https://twitter.com/mgmfilmarts">Twitter</Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/c/MGMFilmArts">YouTube</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="offcanvas__mid">
            <div className="offcanvas__menu-wrapper">
              <nav className="offcanvas__menu">
                <ul className="menu-anim">
                  <li>
                    <Link to="index"
>home</Link>
                  </li>
                  <li>
                    <Link>About us</Link>
                    <ul>
                      <li>
                        <Link to="about-mgm-university"
>MGM University</Link>
                      </li>
                      <li>
                        <Link to="our-pillars"
>OUR PILLARS</Link>
                      </li>
                      <li>
                        <Link to="about-mgm-film-arts"
>
                          ABOUT MGM FILM ARTS
                        </Link>
                      </li>
                      <li>
                        <Link to="faculty"
>FACULTY</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>Academics</Link>
                    <ul>
                      <li>
                        <Link>Programs</Link>
                        <ul>
                          <li>
                            <Link to="certificate-program-2"
>
                              DIPLOMA IN FILMMAKING
                            </Link>
                          </li>
                          <li>
                            <Link to="graduation-program"
>
                              BA (HONS) IN FILMMAKING
                            </Link>
                          </li>
                          <li>
                            <Link to="ma-in-filmmaking"
>MA IN FILMMAKING</Link>
                          </li>
                          <li>
                            <Link to="certificate-programs-in-film-making"
>
                              SHORT TERM PROGRAMS
                            </Link>
                          </li>
                          <li>
                            <Link to="admission-procedure"
>
                              ADMISSION PROCEDURE
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link>SPECIALIZATIONS </Link>
                        <ul>
                          <li>
                            <Link to="acting-for-films"
>Acting For Films</Link>
                          </li>
                          <li>
                            <Link to="film-editing"
>
                              Film Editing &amp; DI
                            </Link>
                          </li>
                          <li>
                            <Link to="film-direction"
>
                              Film Direction &amp; Screenwriting
                            </Link>
                          </li>
                          <li>
                            <Link to="production-design-art-direction"
>
                              Production Design &amp; Art Direction
                            </Link>
                          </li>
                          <li>
                            <Link to="cinematography"
>
                              Cinematography &amp; DI
                            </Link>
                          </li>
                          <li>
                            <Link to="vfx-animation"
>VFX &amp; Animation</Link>
                          </li>
                          <li>
                            <Link to="sound-design-music-production"
>
                              Sound Design &amp; Music Production
                            </Link>
                            <ul className="sub-dropdown">
                              <li>
                                <Link to="https://mgmfilmarts.com/dj-ing-and-music-production-programs">
                                  DJ-ing and Music Production
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link>ACTIVITIES</Link>
                    <ul>
                      <li>
                        <Link to="https://mgmfilmarts.com/film-festival-attendance">
                          FILM FESTIVAL ATTENDENCE
                        </Link>
                      </li>
                      <li>
                        <Link to="audition"
>AUDITION</Link>
                      </li>
                      <li>
                      <Link to="https://mgmfilmarts.com/gallery">
                              GALLERY
                            </Link> 
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/mgm-university-film-society">
                          MGM FILM SOCIETY
                        </Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/gallery">
                          CAMPUS LIFE
                        </Link>
                        <ul className="sub-dropdown">
                          <li>
                            <Link to="https://mgmfilmarts.com/stalwarts-about-mgm-film-arts">
                              STALWARTS ABOUT MGM FILM ARTS
                            </Link>
                          </li>
                          <li>
                            <Link to="https://mgmfilmarts.com/placements">
                              PLACEMENTS
                            </Link>
                          </li>
                          <li>
                            <Link to="https://mgmfilmarts.com/masterclass">
                              MASTERCLASS
                            </Link>
                          </li>
                          <li>
                          </li>
                          <li>
                            <Link to="https://mgmfilmarts.com/student-reviews">
                              STUDENT REVIEWS
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/events">EVENTS</Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/film-productions">
                          FILM PRODUCTIONS
                        </Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/methodology">
                          METHODOLOGY
                        </Link>
                      </li>
                      <li>
                        <Link to="javascript:;">
                          STUDENT'S PROJECTS &amp; AWARDS
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="facilities"
>Facilities</Link>
                  </li>
                  <li>
                    <Link>FTII COURSES</Link>
                    <ul>
                      <li>
                        <Link to="https://mgmfilmarts.com/ftii-courses">
                          FILM APPRECIATION COURSE
                        </Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/mobile-journalism">
                          SMART PHONE JOURNALISM
                        </Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/visual-storytelling">
                          VISUAL STORYTELLING
                        </Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/digital-film-production">
                          DIGITAL FILM PRODUCTION
                        </Link>
                      </li>
                      <li>
                        <Link to="https://mgmfilmarts.com/screen-acting">
                          SCREEN ACTING
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="contact-us">CONTACT US</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="offcanvas__right">
            <div className="offcanvas__contact">
              <h3>Get in touch</h3>
              <ul>
                <li>
                  <span>
                    MGM University, N-6, CIDCO, <br /> CHH.Sambhajinagar
                    (Aurangabad), Maharashtra, India
                  </span>
                </li>
                <li>
                  <Link to="tel:02406481052">0240-6481052</Link>
                </li>
                <li>
                  <Link to="mailto:hodfilmarts@mgmu.ac.in">
                    hodfilmarts@mgmu.ac.in
                  </Link>
                </li>
              </ul>
            </div>
            <img src="assets/imgs/shape/11.png" alt="shape" className="shape-1" />
            <img src="assets/imgs/shape/12.png" alt="shape" className="shape-2" />
          </div>
          <div className="offcanvas__close">
            <button type="button" id="close_offcanvas">
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
      </div>
      {/* <!-- Offcanvas area end --> */}
    </>
  );
};
