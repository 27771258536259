import React from 'react'
import { Header } from '../../Components/Common/Header'

export const StalWartsAboutMgmFilmArts = () => {
  return (
    <>
    <Header />
    <main>
                <section className="job__detail-top pt-150 pb-100"
                    style={{
                        background: `url(${require("../../assets/imgs/bg/mgmu.jpg?v=0.2.1")})`
                        }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="sec-title-wrapper">
                                    <h2 className="sec-title animation__char_come text-white mt-5">STALWARTS ABOUT FILM ARTS
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job__detail">
                    <div className="container g-0">

                        <div className="row">
                            
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                <div className="job__detail-wrapper">
                                    <div className="job__detail-content" style={{PaddingTop: "0 !important;"}}>
                                        <h2 style={{PaddingTop: "22px;"}}>N. CHANDRA (Producer, Director)</h2>
                                        <p>I humbly accept your offer as Advisor and Mentor. I will
                                            take this opportunity to contribute my experience of past 50 years in cinema
                                            to shape the future of cinema, that is, the students of MGM Film Arts</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Marianne Borgo (Actor)</h2>
                                        <p>MGM Film arts is created with great passion and love for cinema. It will
                                            inculcate great aesthetic values in the creations of makers produced by MGM
                                            Film Arts.</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Girish Kasarwalli (Director)</h2>
                                        <p>MGM Film Arts is an ideal platform for budding filmmakers. The atmosphere and
                                            facilities at MGM Film Arts ideal for the grooming of budding artists of
                                            cinema.</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Kiran Shantaram (Producer, Director)</h2>
                                        <p>When I visited MGM Film Institute I was very happy, there are very few
                                            institutes in our country to train and inspire the students of cinema. I am
                                            impressed to see the professional facility provided to students. I am
                                            thankful to the University for naming the best preview theatre as Chitrapati
                                            Dr. V. Shantaram Theatre.</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Adoor Gopalakrishnan (Producer, Director)</h2>
                                        <p>I am sure that MGM will produce talented future film makers. MGM Film Arts
                                            gives you an opportunity to explore yourselves. The atmosphere and
                                            facilities of MGM Film Arts is the best I have seen.</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Madhur Bhandarkar (Producer, Director)</h2>
                                        <p>MGM Film Arts is one of the finest Film making Institutes. The
                                            State-Of-The-Art studio facility will nurture the talent in budding
                                            filmmakers.</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Arundhati Roy (Writer, Actor)</h2>
                                        <p>I am surprised to see the professional facility provided to film students. I
                                            wish to make a film with MGM Film Arts and its students.</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Bjorn Holmgrain (Swedish Counsel)</h2>
                                        <p>I am overwhelmed to see that MGM Film Arts has created Bergman's Courtyard at
                                            its premise. It shows there love towards Ingmar Bergman and Swedish cinema.
                                        </p>
                                        <h2 style={{PaddingTop: "22px;"}}>Mr. Shreeram Raghvan (Director)</h2>
                                        <p>I had visited MGM Department of Film Arts for the first time and I am
                                            thrilled to see so many facilities that are available for the students here.
                                            I hope these students work hard and make the institute shine. There is one
                                            MGM in Hollywood so let's make the name of our MGM too!</p>
                                        <h2 style={{PaddingTop: "22px;"}}>Prof. Waman Kenre (Producer, Director)</h2>
                                        <p>I am very delighted to see the campus of MGM Department of Film Arts for the
                                            first time. It is well-equipped with the latest and high tech equipment for
                                            cinema. I hope this institute may reach great heights in the coming years.
                                        </p>
                                        <h2 style={{PaddingTop: "22px;"}}>Om Raut (Director - Tanhaji)</h2>
                                        <p>I have visited many film schools in India but MGM Department of Film Arts is
                                            really an upgraded one. The facilities and infrastructure, especially the
                                            editing studios are really an appreciative attempt set by the institute.
                                            There is also a DI Lab which I have never seen in any film school in India.
                                            A filmmaker is a technician first, and the institute have given importance
                                            to technical knowledge of film making which is really remarkable.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    </main>
    
    </>
  )
}
