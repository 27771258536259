import React,{useLayoutEffect,useRef} from "react";
import { Header } from "../../../Components/Common/Header";

import { Link } from "react-router-dom";

export const ProductionDesignArtDirection = () => {  const el = useRef();
        
  return (
    <>      <Header />

        <main>
            <section className="job__detail-top pt-150 pb-100"
                style={{background: "url(assets/imgs/specilization/acting.jpg?v=0.0.4) !important;"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="sec-title-wrapper">
                                <h2 className="sec-title animation__char_come text-white mt-5">Production Design &amp;
                                    Art Direction</h2>
                                <p className="text-white">Create a world that doesn't- but could- exist</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="job__detail">
                <div className="container g-0 pb-30">

                    <div className="row">
                        {/* <!-- <div className="text-center mt-5">
                            <h1>Diploma In Filmmaking</h1>
                        </div> --> */}
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                            <div className="job__detail-wrapper">
                                <h2 className="sec-title">Program Intro</h2>
                                {/* <!-- <ul className="job__detail-meta">
                                    <li><span>Location</span> 2541, Austin, New York</li>
                                    <li><span>Date</span> 24, June 2022</li>
                                    <li><span>Job Type</span> Full time (Remote)</li>
                                </ul> --> */}
                                <div className="job__detail-content">
                                    <p>Architecture & Interior Design are all popular choices. that art degree may
                                        serve you well in your particular field. But the Production design is the
                                        best course to enter the film industry. Production designers guide the art
                                        department on films which include set costumes, lighting, and makeup. But
                                        more focused on creating an engaging backdrop for the story.</p>
                                    <h2>Program Overview</h2>
                                    <p>The Young Aspirants who wish to pursue their passion for Art Direction into a
                                        career. this fruitful course is specially designed with various elective
                                        specializations where students can master their desired technical skills
                                        along with 30% Theory and 70% Practical knowledge.</p>
                                    <p>Students will get access to the ‘State-Of-The-Art' facilities as well as
                                        professional studios with world-class equipment and technology.</p>
                                    <h2>Filmmaking Facilities</h2>
                                    <p>Film City Editing Studios Sound Recording & Mixing Studio Color Lab (DI)
                                        Preview Theatre (4K, 9.2.1 Dolby) Film Cameras Cine Lights Cine Lenses
                                        Chroma Floor Shooting Locations Costume Library Art Inventory VFX Lab
                                        Miniature Studio</p>
                                    <h2 className="mb-3">Program Benefits</h2>
                                    <ul>
                                        <li>On Job Training</li>
                                        <li>In-depth, hands-on, practical training</li>
                                        <li>Training with practicing film & TV industry tutors</li>
                                        <li>Participation in In-house and Professional Film Projects</li>
                                        <li>Film festival access</li>
                                        <li>Easy access to Studios, shooting facilities & Art Inventory</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="portfolio__service service-v5">
                <div className="container">
                    <div className="portfolio__service-list">
                        <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                <div className="portfolio__service-item">
                                    <Link to="/ma-in-production-design-art-direction">
                                        <h3 className="ps-title">MA in Production Design<br/> &amp; Art Direction</h3>
                                        {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                <div className="portfolio__service-item">
                                    <Link to="/ba-hons-production-design-art-direction">
                                        <h3 className="ps-title">BA (HONS) in Production Design<br/> &amp; Art Direction
                                        </h3>
                                        {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                <div className="portfolio__service-item">
                                    <Link to="/diploma-in-production-design-and-art-direction">
                                        <h3 className="ps-title">Diploma in Production Design<br/> &amp; Art Direction
                                        </h3>
                                        {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                <div className="portfolio__service-item">
                                    <Link to="/production-design-short-programs">
                                        <h3 className="ps-title">Short Programs <br/> (6 Months)</h3>
                                        {/* <!-- <ul>
                                            <li>+ WordPress</li>
                                            <li>+ Python</li>
                                            <li>+ PHP &amp Laravel</li>
                                        </ul> --> */}
                                    </Link>
                                </div>
                            </div>
                            {/* <!-- <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 justify-content-center">
                                <div className="btn_wrapper" style={{width: "100%;"}}>
                                    <Link to="https://mgmfilmarts.com/production-design-short-programs/"
                                        target="_blank" className="wc-btn-primary btn-hover btn-item"><span></span>
                                        Download<br/> brochure <i className="fa-solid fa-arrow-right"></i></Link>
                                </div>
                            </div> --> */}
                        </div>
                    </div>
                </div>
            </section>
        </main>

 </>
  )
}
