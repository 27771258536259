import React, { useEffect, useState } from "react";
import { Header } from "../../Components/Common/Header";

export const ScholarshipLeads = () => {
  const [workshopData, setWorkshopData] = useState([]);
  const [showContent, setShowContent] = useState(false);
  const [passwordChecked, setPasswordChecked] = useState(false);
  const [password, setPassword] = useState("");
  const correctPassword = process.env.REACT_APP_PASSWORD2;
  const apiUrl = process.env.REACT_APP_API_URL2;

  const checkPassword = () => {
    if (password === correctPassword) {
      setShowContent(true);
      setPasswordChecked(true);
      fetchData();
    } else {
      alert("Incorrect password! Access denied.");
    }
  };

  const fetchData = () => {
    fetch(apiUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error fetching data");
        }
      })
      .then((data) => {
        const jsonData = data;
        setWorkshopData(jsonData.Data.Records);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          alert("The requested resource could not be found.");
        } else {
          alert("An error occurred while fetching the data.");
        }
      });
  };

  const exportTableToExcel = (tableID, filename = "") => {
    const downloadLink = document.createElement("a");
    const dataType = "application/vnd.ms-excel";
    const table = document.getElementById(tableID);
    const tableHTML = table.outerHTML.replace(/ /g, "%20");

    downloadLink.href = "data:" + dataType + "," + tableHTML;
    downloadLink.download = filename + ".xls";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Header />
      {showContent && (
        <main>
          <section className="table pt-150 mt-5">
            <div className="container">
              <h2 className="sec-title text-center">Scholarship Leads</h2>
              <div id="tableContainer" className="table table-responsive">
                <table className="table table-dark table-hover" id="myTable">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th>FirstName</th>
                      <th>LastName</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Gender</th>
                      <th>ProgramName</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workshopData.map((item, index) => (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <td>{item.FirstName}</td>
                        <td>{item.LastName}</td>
                        <td>{item.Email}</td>
                        <td>{item.Phone}</td>
                        <td>{item.Gender}</td>
                        <td>{item.ProgramName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <a
                className="scroll-top"
                onClick={() => exportTableToExcel("myTable", "Scholarship Leads")}
              >
                Download Excel
              </a>
            </div>
          </section>
        </main>
      )}
      {!showContent && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  checkPassword();
                }}
              >
                <div className="modal-body" style={{ background: "none" }}>
                  <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary mt-3 right-0"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {!showContent && <div className="modal-backdrop show"></div>}
    </>
  );
};
